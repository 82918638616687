import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	// Paper,
	Container,
	Typography,
	Box,
	TextField,
	Select,
	InputLabel,
	MenuItem,
	// FormControl,
	// FormControlLabel,
	Grid,
	// Checkbox,
	Button,
	Autocomplete,
	// styled,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import medium from "../../Image/long.png";
import axios from "axios";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
const mockStatesData = {
	"United States": [
		"California",
		"Texas",
		"New York",
		"Florida",
		"Illinois",
		"Pennsylvania",
		"Ohio",
		"Georgia",
		"North Carolina",
		"Michigan",
	],
	Canada: [
		"Ontario",
		"Quebec",
		"British Columbia",
		"Alberta",
		"Manitoba",
		"Saskatchewan",
		"Nova Scotia",
		"New Brunswick",
		"Newfoundland and Labrador",
		"Prince Edward Island",
	],
	Australia: [
		"New South Wales",
		"Victoria",
		"Queensland",
		"Western Australia",
		"South Australia",
		"Tasmania",
		"Northern Territory",
		"Australian Capital Territory",
	],
	India: [
		"Maharashtra",
		"Karnataka",
		"Tamil Nadu",
		"Uttar Pradesh",
		"Gujarat",
		"Rajasthan",
		"Madhya Pradesh",
		"West Bengal",
		"Bihar",
		"Punjab",
	],
	China: [
		"Guangdong",
		"Shandong",
		"Henan",
		"Sichuan",
		"Jiangsu",
		"Hebei",
		"Hunan",
		"Anhui",
		"Hubei",
		"Zhejiang",
	],
	Brazil: [
		"São Paulo",
		"Rio de Janeiro",
		"Minas Gerais",
		"Bahia",
		"Paraná",
		"Rio Grande do Sul",
		"Pernambuco",
		"Ceará",
		"Pará",
		"Santa Catarina",
	],
	Russia: [
		"Moscow",
		"Saint Petersburg",
		"Novosibirsk",
		"Yekaterinburg",
		"Nizhny Novgorod",
		"Kazan",
		"Chelyabinsk",
		"Omsk",
		"Samara",
		"Rostov-on-Don",
	],
	Germany: [
		"Bavaria",
		"North Rhine-Westphalia",
		"Baden-Württemberg",
		"Hesse",
		"Saxony",
		"Lower Saxony",
		"Berlin",
		"Brandenburg",
		"Schleswig-Holstein",
		"Saxony-Anhalt",
	],
	France: [
		"Île-de-France",
		"Provence-Alpes-Côte d'Azur",
		"Nouvelle-Aquitaine",
		"Occitanie",
		"Auvergne-Rhône-Alpes",
		"Hauts-de-France",
		"Grand Est",
		"Brittany",
		"Pays de la Loire",
		"Normandy",
	],
	"United Kingdom": ["England", "Scotland", "Wales", "Northern Ireland"],
	Italy: [
		"Lombardy",
		"Lazio",
		"Campania",
		"Sicily",
		"Veneto",
		"Piedmont",
		"Emilia-Romagna",
		"Tuscany",
		"Apulia",
		"Liguria",
	],
	Mexico: [
		"Mexico City",
		"Jalisco",
		"Nuevo León",
		"Puebla",
		"Guanajuato",
		"Veracruz",
		"Chiapas",
		"Tamaulipas",
		"Oaxaca",
		"Chihuahua",
	],
	Japan: [
		"Tokyo",
		"Osaka",
		"Kyoto",
		"Hokkaido",
		"Fukuoka",
		"Aichi",
		"Hyogo",
		"Chiba",
		"Saitama",
		"Hiroshima",
	],
	"South Africa": [
		"Gauteng",
		"KwaZulu-Natal",
		"Western Cape",
		"Eastern Cape",
		"Limpopo",
		"Mpumalanga",
		"North West",
		"Free State",
		"Northern Cape",
	],
	Argentina: [
		"Buenos Aires",
		"Córdoba",
		"Santa Fe",
		"Mendoza",
		"Tucumán",
		"Salta",
		"Entre Ríos",
		"Misiones",
		"Chaco",
		"Corrientes",
	],
	Spain: [
		"Andalusia",
		"Catalonia",
		"Madrid",
		"Valencian Community",
		"Galicia",
		"Castile and León",
		"Basque Country",
		"Castilla-La Mancha",
		"Canary Islands",
		"Murcia",
	],
	Nigeria: [
		"Lagos",
		"Kano",
		"Kaduna",
		"Rivers",
		"Oyo",
		"Katsina",
		"Bauchi",
		"Anambra",
		"Borno",
		"Delta",
	],
	Indonesia: [
		"Jakarta",
		"West Java",
		"Central Java",
		"East Java",
		"Banten",
		"Yogyakarta",
		"Bali",
		"South Sumatra",
		"North Sumatra",
		"Riau",
	],
	Pakistan: [
		"Punjab",
		"Sindh",
		"Khyber Pakhtunkhwa",
		"Balochistan",
		"Islamabad Capital Territory",
		"Gilgit-Baltistan",
		"Azad Kashmir",
	],
	Bangladesh: [
		"Dhaka",
		"Chittagong",
		"Khulna",
		"Rajshahi",
		"Barisal",
		"Sylhet",
		"Rangpur",
		"Mymensingh",
	],
	Turkey: [
		"Istanbul",
		"Ankara",
		"Izmir",
		"Bursa",
		"Antalya",
		"Adana",
		"Konya",
		"Gaziantep",
		"Şanlıurfa",
		"Kocaeli",
	],
	Egypt: [
		"Cairo",
		"Alexandria",
		"Giza",
		"Shubra El Kheima",
		"Port Said",
		"Suez",
		"Mansoura",
		"El-Mahalla El-Kubra",
		"Tanta",
		"Asyut",
	],
	Thailand: [
		"Bangkok",
		"Chiang Mai",
		"Phuket",
		"Pattaya",
		"Hat Yai",
		"Nakhon Ratchasima",
		"Udon Thani",
		"Khon Kaen",
		"Nakhon Si Thammarat",
		"Songkhla",
	],
	Vietnam: [
		"Ho Chi Minh City",
		"Hanoi",
		"Da Nang",
		"Haiphong",
		"Can Tho",
		"Bien Hoa",
		"Nha Trang",
		"Hue",
		"Vung Tau",
		"Nam Dinh",
	],
	Philippines: [
		"Metro Manila",
		"Cebu",
		"Davao",
		"Baguio",
		"Zamboanga",
		"Cagayan de Oro",
		"Iloilo",
		"Bacolod",
		"General Santos",
		"Batangas",
	],
	Iran: [
		"Tehran",
		"Mashhad",
		"Isfahan",
		"Karaj",
		"Shiraz",
		"Tabriz",
		"Qom",
		"Ahvaz",
		"Kermanshah",
		"Urmia",
	],
	"South Korea": [
		"Seoul",
		"Busan",
		"Incheon",
		"Daegu",
		"Daejeon",
		"Gwangju",
		"Suwon",
		"Ulsan",
		"Changwon",
		"Goyang",
	],
	Colombia: [
		"Bogotá",
		"Medellín",
		"Cali",
		"Barranquilla",
		"Cartagena",
		"Cúcuta",
		"Soledad",
		"Ibagué",
		"Bucaramanga",
		"Pereira",
	],
	Malaysia: [
		"Kuala Lumpur",
		"Selangor",
		"Penang",
		"Johor",
		"Sabah",
		"Sarawak",
		"Perak",
		"Pahang",
		"Kedah",
		"Negeri Sembilan",
	],
	"Saudi Arabia": [
		"Riyadh",
		"Jeddah",
		"Mecca",
		"Medina",
		"Dammam",
		"Khobar",
		"Tabuk",
		"Buraidah",
		"Khamis Mushait",
		"Hail",
	],
	Chile: [
		"Santiago",
		"Valparaíso",
		"Concepción",
		"La Serena",
		"Antofagasta",
		"Temuco",
		"Rancagua",
		"Iquique",
		"Puerto Montt",
		"Copiapó",
	],
	Ukraine: [
		"Kyiv",
		"Kharkiv",
		"Odessa",
		"Dnipro",
		"Donetsk",
		"Zaporizhzhia",
		"Lviv",
		"Kryvyi Rih",
		"Mykolaiv",
		"Mariupol",
	],
	Netherlands: [
		"North Holland",
		"South Holland",
		"Utrecht",
		"Gelderland",
		"North Brabant",
		"Limburg",
		"Zeeland",
		"Friesland",
		"Overijssel",
		"Groningen",
	],
	Belgium: ["Brussels", "Flanders", "Wallonia"],
	Greece: [
		"Attica",
		"Central Macedonia",
		"Thessaly",
		"Crete",
		"Eastern Macedonia and Thrace",
		"Epirus",
		"Ionian Islands",
		"North Aegean",
		"Peloponnese",
		"South Aegean",
	],
	Portugal: [
		"Lisbon",
		"Porto",
		"Braga",
		"Aveiro",
		"Faro",
		"Setúbal",
		"Coimbra",
		"Madeira",
		"Azores",
	],
	Sweden: [
		"Stockholm",
		"Gothenburg",
		"Malmö",
		"Uppsala",
		"Västerås",
		"Örebro",
		"Linköping",
		"Helsingborg",
		"Jönköping",
		"Norrköping",
	],
	Switzerland: [
		"Zurich",
		"Geneva",
		"Basel",
		"Lausanne",
		"Bern",
		"Winterthur",
		"Lucerne",
		"St. Gallen",
		"Lugano",
		"Biel/Bienne",
	],
	Austria: [
		"Vienna",
		"Lower Austria",
		"Upper Austria",
		"Styria",
		"Tyrol",
		"Carinthia",
		"Salzburg",
		"Vorarlberg",
		"Burgenland",
	],
	Norway: [
		"Oslo",
		"Bergen",
		"Trondheim",
		"Stavanger",
		"Kristiansand",
		"Mandal",
		"Akershus",
		"Hedmark",
		"Oppland",
		"Vestfold",
	],
};

const AppForm = () => {
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState("School of Product");
	const [selectedState, setSelectedState] = useState("");

	const [schoolSelection, setSchoolSelection] = useState("");
	const [programSelection, setProgramSelection] = useState("");
	const [programOptions, setProgramOptions] = useState([]);

	const [name, setName] = useState("");
	// phone
	const [phone, setPhone] = useState("");

	let country_code = "";

	const handlePhoneChange = (e) => {
		console.log("phone", phone);
		console.log("name", name);
		//   setPhone(value);
	};

	// programs

	const Product = ["Product Design", "Product Management", "Product Marketing"];

	const Data = ["Data Analysis", "Data Science", "Data Engineering"];

	const Software = [
		"Frontend Engineering",
		"Backend Engineering",
		"Cloud Engineering",
	];

	const Communication = [
		"digital Marketing",
		"Social media Marketing",
		"virtual Assistant",
	];

	// handling selected school

	const handleSchoolSelection = (event) => {
		const selectedvalue = event.target.value;

		setSchoolSelection(selectedvalue);

		if (selectedvalue === "Product") {
			setProgramOptions(Product);
		} else if (selectedvalue === "Data") {
			setProgramOptions(Data);
		} else if (selectedvalue === "Software") {
			setProgramOptions(Software);
		} else {
			setProgramOptions(Communication);
		}

		setProgramSelection("");
	};

	const handleProgramSelection = (event) => {
		setProgramSelection(event.target.value);
	};

	//  const [countries, setCountries] = useState([]);
	// 	const [selectedCountry, setSelectedCountry] = useState("");
	// 	const [states, setStates] = useState([]);
	// 	const [selectedState, setSelectedState] = useState("");

	useEffect(() => {
		// Fetch the list of countries from the REST Countries API
		axios
			.get("https://restcountries.com/v2/all")
			.then((response) => {
				setCountries(response.data);
				console.log(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handleCountryChange = (event) => {
		const countryName = event.target.value;
		const country = countries.find((c) => c.name === countryName);

		setSelectedCountry(countryName);

		if (mockStatesData[countryName]) {
			setStates(mockStatesData[countryName]);
		} else {
			console.log("No states found for the selected country");
			setStates([]);
		}

		setSelectedState("");
	};

	// const handleStateChange = (event) => {
	// 	setSelectedState(event.target.value);
	// };

	const [dob, setDOB] = useState("");

	const [gender, setGender] = useState("Male");

	const genderChange = (event) => {
		setGender(event.target.value);
	};

	const [education, setEducation] = useState("PHD");

	const educationChange = (event) => {
		setEducation(event.target.value);
	};

	const [employment, setEmployment] = useState("Unemployed");

	const employmentChange = (event) => {
		setEmployment(event.target.value);
	};

	const [study, setStudy] = useState("programming");

	const studyChange = (event) => {
		setStudy(event.target.value);
	};

	const [info, setInfo] = useState("facebook");

	const infoChange = (event) => {
		setInfo(event.target.value);
	};

	const head = {
		// backgroundColor: 'red',
		position: "relative",
		display: "inline",
		// marginBottom:'40px'
	};

	return (
		<div style={{ marginTop: "120px" }}>
			<Container maxWidth="lg">
				<div
					style={{
						// marginLeft:'0px',
						marginBottom: "25px",
						paddingLeft: "0px",
						position: "relative",

						fontFamily: "Lato",
					}}
					className="underlined">
					<Typography variant="h4" sx={head}>
						{" "}
						Application Form{" "}
						<img
							src={medium}
							alt=""
							style={{
								position: "obsolute",
								// left: "-270px",
								top: "-18px",
								zIndex: "-200",
								width: "265px",
							}}
							className="medium"
						/>
					</Typography>
				</div>

				<Typography variant="text" sx={{ fontSize: "15px", color: "#7d7d7d" }}>
					Fill In Your Details
				</Typography>

				<Box sx={{ marginTop: "10px" }}>
					<form>
						<Grid container spacing={2}>
							{/* first row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="first-name">First Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="first-name"
									name="first-name"
									placeholder="Enter First Name"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="last-name">Middle Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="middle-name"
									name="middle-name"
									placeholder="Enter middle Name"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="last-name">Last Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="last-name"
									name="last-name"
									placeholder="Enter Last Name"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								{/* <FormControl fullWidth marginTop= '10px'  size="small"> */}
								<InputLabel id="gender-label">Gender</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="gender-label"
									value={gender}
									fullWidth
									size="small"
									placeholder="Select Gender"
									// label="Gender"
									onChange={genderChange}>
									{/* <MenuItem value="">
                                        <em>None</em>
                                        </MenuItem> */}
									<MenuItem selected value={"Male"}>
										Male
									</MenuItem>
									<MenuItem value={"Female"}>Female</MenuItem>
								</Select>
								{/* </FormControl> */}
							</Grid>
							{/* first row end */}

							{/* second row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="email">Email</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="email"
									name="email"
									placeholder="Enter Email address"
									required
									fullWidth
									autoComplete="email"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="edu-label">Education Level</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="edu-label"
									value={education}
									fullWidth
									size="small"
									placeholder="Select Educational Level"
									onChange={educationChange}>
									{/* <MenuItem value="">
                            <em>None</em>
                            </MenuItem> */}
									<MenuItem value={"High-School"}>High School</MenuItem>
									<MenuItem value={"PHD"}>PHD</MenuItem>
									<MenuItem value={"OND"}>OND</MenuItem>
									<MenuItem value={"HND"}>HND</MenuItem>
									<MenuItem value={"BSC"}>B.Sc</MenuItem>
									<MenuItem value={"MSC"}>M.Sc</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<InputLabel id="dob">Date Of Birth</InputLabel>
									<DatePicker
										id="dob"
										fullWidth
										// label="Child's Date of Birth"
										placeholder="Select Date Of Birth"
										slotProps={{
											textField: { size: "small", fullWidth: true },
										}}
										required
										value={dob}
										onChange={(newValue) => setDOB(newValue)}
									/>
								</LocalizationProvider>
							</Grid>
							{/* second row end */}

							{/* third row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="country-label">Country</InputLabel>
								<TextField
									select
									size="small"
									id="country"
									value={selectedCountry}
									onChange={handleCountryChange}
									SelectProps={{
										native: true,
									}}
									fullWidth>
									<option value="">Select a country</option>
									{countries.map((country) => (
										<option key={country.alpha3Code} value={country.name}>
											{country.name}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="state">State Of Residence</InputLabel>
								<Autocomplete
									freeSolo
									size="small"
									options={states}
									value={selectedState}
									onChange={(event, newValue) => setSelectedState(newValue)}
									inputValue={selectedState}
									onInputChange={(event, newInputValue) =>
										setSelectedState(newInputValue)
									}
									renderInput={(params) => (
										<TextField
											size="small"
											{...params}
											label="Enter state"
											placeholder="Enter state"
											required
											fullWidth
											autoComplete="off"
										/>
									)}
								/>
							</Grid>
							{/* </div> */}

							{/* </Grid> */}
							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="employment">Employment Status</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="employment"
									value={employment}
									fullWidth
									size="small"
									// placeholder="Select Educational Level"
									// label="Gender"
									onChange={employmentChange}>
									{/* <MenuItem value="">
                            <em>Country</em>
                            </MenuItem> */}
									<MenuItem value={"Unemployed"}>Unemployed</MenuItem>
									<MenuItem value={"Student"}>Student</MenuItem>
									<MenuItem value={"Employed"}>Employed</MenuItem>
									<MenuItem value={"Self-Employed"}>Self-Employed</MenuItem>
								</Select>
							</Grid>

							{/* fourth row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="course">Choose School of Study</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="course"
									// value={gender}
									fullWidth
									size="small"
									// placeholder="Choose Course of Study"
									// label="Gender"
									value={schoolSelection}
									onChange={handleSchoolSelection}>
									{/* <MenuItem value="">
                        <em>state</em>
                        </MenuItem> */}
									<MenuItem value={"Product"}>School of Product</MenuItem>
									<MenuItem value={"Data"}>School of Data</MenuItem>
									<MenuItem value={"Software"}>
										School of Software Engineering
									</MenuItem>
									<MenuItem value={"Communication"}>
										School of Communication
									</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="course">Choose Program</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="course"
									// value={gender}
									fullWidth
									size="small"
									// placeholder="Choose Course of Study"

									value={programSelection}
									onChange={handleProgramSelection}>
									{/* <MenuItem value="">
                        <em>Choose a Program</em>
                        </MenuItem> */}

									{programOptions.map((programs) => (
										<MenuItem value={programs}>{programs}</MenuItem>
									))}
								</Select>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="phone">Phone Number</InputLabel>
								{/* <TextField
									sx={{ pb: 4 }}
									size="small"
									id="phone"
									name="phone"
									placeholder="Enter Your Phone Number"
									required
									fullWidth
									autoComplete="number"
									
								/> */}

								<PhoneInput
									country={"us"}
									value={`${country_code}${phone}`}
									onChange={(e, phone) => {
										handlePhoneChange(e, phone, "connect");
									}}
									inputStyle={{
										width: "100%",
										height: "40px",
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="info">Where did you hear about us?</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="info"
									value={info}
									fullWidth
									size="small"
									// placeholder="Select Educational Level"
									// label="Gender"
									onChange={infoChange}>
									{/* <MenuItem value="">
                        <em>Country</em>
                        </MenuItem> */}
									<MenuItem value={"facebook"}>Facebook</MenuItem>
									<MenuItem value={"friend"}>Friend</MenuItem>
									<MenuItem value={"instagram"}>Instagram</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="last-name">Referal Code</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="referal-name"
									name="referal-name"
									placeholder="Enter Referal Code"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>
						</Grid>

						<Box
							sx={{
								marginTop: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}>
							<Typography variant="text">
								By clicking continue, I agree to the{" "}
								<Link style={{ textDecoration: "None", color: "blue" }}>
									Terms of Use
								</Link>{" "}
								and acknowledge that i have read the{" "}
								<Link style={{ textDecoration: "None", color: "blue" }}>
									Privacy Policy{" "}
								</Link>{" "}
							</Typography>
							<Button
								variant="contained"
								type="submit"
								sx={{
									mt: 3,
									width: "30%",
									mb: 2,
									backgroundColor: "blue",
									"&:hover": {
										backgroundColor: "blue",
										borderColor: "#0062cc",
										boxShadow: "none",
									},
								}}>
								<Link
									to="/setpassword"
									style={{ color: "white", textDecoration: "none" }}>
									Proceed
								</Link>
							</Button>
							<p style={{ textAlign: "center" }}>
								Already have an account?{" "}
								<Link style={{ textDecoration: "none", color: "blue" }}>
									Log In
								</Link>{" "}
							</p>
						</Box>
					</form>
				</Box>
			</Container>
		</div>
	);
};

export default AppForm;
