import React from "react";

import styled from "styled-components";

const Dropdown = ({ handleDropdownClose }) => {
	// const onMouseLeave= handleDropdownClose;

	return (
		<DisplayWrap>
			<ul
				className="dropdown-menu"
				onMouseLeave={() => {
					handleDropdownClose(false);
				}}
				style={{
					width: "200px",
				}}>
				<li>
					<a href="/" style={{ color: "black" }}>
						School of Software
					</a>
				</li>
				<li>
					<a href="/" style={{ color: "black" }}>
						School of Design
					</a>
				</li>
				<li>
					<a href="/" style={{ color: "black" }}>
						School of Communication
					</a>
				</li>
				<li>
					<a href="/" style={{ color: "black" }}>
						School of Data
					</a>
				</li>
			</ul>
		</DisplayWrap>
	);
};

const DisplayWrap = styled.div`
	.dropdown-menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 95%;
		left: 35vw;
		gap: 3px;
		align-items: center;
		justify-content: center;
		background-color: #f5f5f5;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 13vw;
		border-radius: 5px;
		z-index: 1;
	}
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.dropdown-menu {
			display: none;
			flex-direction: column;
			position: absolute;
			top: 0;
			left: 20vw;
			gap: 3px;
			align-items: center;
			justify-content: center;
			background-color: #f5f5f5;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 13vw;
			border-radius: 5px;
			z-index: 1;
		}
	}
	@media (max-width: 40em) {
		.dropdown-menu {
			display: none;
			flex-direction: column;
			position: absolute;
			top: 0;
			left: 20vw;
			gap: 3px;
			align-items: center;
			justify-content: center;
			background-color: #f5f5f5;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 13vw;
			border-radius: 5px;
			z-index: 1;
		}
	}

	.dropdown-menu li {
		margin: 0;
		padding: 8px;
		font-size: 12px;
		text-align: left;
	}

	.dropdown-menu a {
		color: #black;
		text-decoration: none;
	}

	// .dropdown:hover .dropdown-menu {
	//   display: block;
	// }
	.dropdown.open .dropdown-menu {
		//   display: block;
	}
`;

export default Dropdown;
