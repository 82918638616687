import React from "react";
// import Navbar from '../Navbar'
import Footer from "../WaitingPage/Footer";
import { Outlet } from "react-router-dom";
import Anime from "../Animated/Anime";
// import ScrollToTopButton from "../Buttton/Button";
import ScrollFloat from "../Buttton/Float";

const SharedLayout = () => {
	return (
		<main className="w-full overflow-x-hidden">
			<Anime />
			<Outlet />
			<ScrollFloat />
			<Footer />
		</main>
	);
};

export default SharedLayout;
