import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";
import logo from "../Image/Logo.png";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "../WaitingPage/Dropdown";

const Anime = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// Clean up the event listener when the component unmounts

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		if (!isMenuOpen) {
			// Disable scrolling when the navbar is opened
			document.body.style.overflow = "hidden";
		} else {
			// Enable scrolling when the navbar is closed
			document.body.style.overflow = "auto";
		}
	};
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		// Add an event listener to the window scroll event
		window.addEventListener("scroll", handleScroll);
		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	const [show, setShow] = useState(false);

	const handleClick = () => {
		setShow(true);
	};
	const handleMenuClose = () => {
		setIsMenuOpen(false);
		document.body.style.overflow = "auto";
	};

	const handleScroll = () => {
		// Check the scroll position and update the 'scrolled' state accordingly
		if (window.scrollY > 100) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	const location = useLocation();
	useEffect(() => {
		// Get all the anchor elements in the navbar
		const links = document.querySelectorAll(".nav-list a");

		// Loop through the links and check if their href matches the current URL
		links.forEach((link) => {
			if (link.href === window.location.href) {
				// Add the 'active' class to the matching link
				link.classList.add("active");
			} else {
				// Remove the 'active' class from non-matching links
				link.classList.remove("active");
			}
		});
	}, [location]);

	return (
		<AnimeWrapper>
			<div className={`navbar ${scrolled ? "scrolled" : ""}`}>
				<Link to="/" className="title">
					<img src={logo} alt="" />
				</Link>
				<div className="menu-toggle" onClick={toggleMenu}>
					{isMenuOpen ? <FaTimes /> : <FaBars />}
				</div>
				<ul className={isMenuOpen ? "nav-list open" : "nav-list"}>
					<li className={isMenuOpen ? "animate" : "fade-out"}>
						<Link to="/" onClick={handleMenuClose}>
							Home
						</Link>
					</li>
					<li
						className={isMenuOpen ? "animate" : "fade-out"}
						onMouseEnter={handleClick}>
						<Link to="school" className="dropdown" onClick={handleMenuClose}>
							School
						</Link>
						{show && <Dropdown handleDropdownClose={setShow} />}
					</li>
					<li className={isMenuOpen ? "animate" : "fade-out"}>
						<Link to="faq" onClick={handleMenuClose}>
							FAQS
						</Link>
					</li>

					<li className={isMenuOpen ? "animate" : "fade-out"}>
						<Link to="about" onClick={handleMenuClose}>
							AboutUs
						</Link>
					</li>
					<li className={isMenuOpen ? "animate" : "fade-out"}>
						<Link to="contact" onClick={handleMenuClose}>
							Contact
						</Link>
					</li>

					<div className="a-cta">
						<a
							href="portal.gwctechschoolafrica.com"
							className="a_sign-in_btn"
							onClick={handleMenuClose}>
							{/* <button className="sign-in_btn"> */}
							Login
							{/* </button> */}
						</a>
						<Link
							to="apply"
							className="a_sign-up_btn"
							onClick={handleMenuClose}>
							<button className="sign-up_btn">Apply Now</button>{" "}
						</Link>
					</div>
				</ul>
			</div>
		</AnimeWrapper>
	);
};
const AnimeWrapper = styled.div`
.sign-up_btn{
  outline: none;
    border: none;
    background: transparent;
    color: white;
}
}
.navbar{
    background:#f5f5f5;
    // color:#fff;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:10px;
    transition:all 0.3s ease;
     position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:12vh;
z-index:3;

 
}
.nav-list{
    margin-right:6rem;
    display:flex;
    align-items:center;
    gap:4rem;
    color:#425CE8;
}


.title img{
  height:89px;
}
.navbar.scrolled {
    //  background: #bc4040ce;
    background: #c5c5c5a1;
}
.nav-list a{
    text-decoration:none;
    // display:flex;
}
    li{
        list-style:none;
    margin:0 10px;
}

}
.a_sign-up_btn{
 background:#425CE8;
    color:#fff;
     border:none;
    // border:1px solid #425CE8;
    border-radius:8px;
    padding:10px 20px;
} 
.a_sign-in_btn{
background:#f5f5f5;
color:#425CE8;
 border:1px solid #425CE8;
 border-radius:8px;
 padding:10px 20px;
}
.dropdown {
  position: relative;
  cursor: pointer;
//   padding-inline:22px;
  // display:flex;
  // align-items:center;
  gap:10px;
  font-family: 'Lato';
  
}

 
.a-cta{
  display:flex;
  gap:60px;
}
.menu-toggle{
    cursor:pointer;
    display:none;
    flex-direction:column;
    margin-right:2rem;
}

// .navbar a {
//   color: #333;
//   text-decoration: none;
// }

/* Active link styles */
.navbar a.active {
  color:#425ce8;
  // color: #ff0000; 
  /* Change to your desired color */
  font-weight: bold; /* Apply any other styles you want */
}
//tablet
@media screen and (min-width: 768px) and (max-width: 1024px){
  .navbar{
    background:#f5f5f5;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:10px;
    transition:all 0.3s ease;
     position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:10vh;
z-index:3;

 
}
 .menu-toggle{
        display:flex;
     transition: max-height 0.6s ease-in, opacity 0.5s ease-in;
  animation: fadeInUp 0.6s ease-in-out forwards;
  // background: blue;
    // color: white;
    padding: 4px;
    border-radius: 4px;
    }
    .nav-list a{
    text-decoration:none;
    color:#fff;
    // display:flex;
}

   .a-cta{
  display:flex;
  gap:60px;
  align-self:baseline;
}
    .nav-list{
     position:fixed;
     display: flex;
  flex-direction:column;
    top: 63px;
    left: 0;
    width: 100%;
    height: 100vh;
    gap: 4.5rem;
    align-items: baseline;
    padding-block:4rem;
    background-color: #000000;
animation: fade-out 0.5s ease forwards;
animation-delay:2s;
    padding-inline-start: 16px;
    opacity:0;
    visibility:hidden;

    }
.{
    color:#fff;
}
.dropdown {
  // display:none;
  position: relative;
  cursor: pointer;
//   padding-inline:22px;
  // display:flex;
  // align-items:center;
  gap:10px;
  font-family: 'Lato';
  
}

@keyframes fade-out{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
   
  }
}

@keyframes fadeInLeft{
    from{
        opacity:1
    }
    to{
        opacity:0;
    transform:translateX(-100%);
    }
}
.fade-out {
    -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 4s ease-in-out ;
  opacity:0;
}
.fade-out:nth-child(5) {
  animation-delay: 0.5s;
}
.fade-out:nth-child(4) {
  animation-delay: 0.8s;
}

.fade-out:nth-child(3) {
  animation-delay: 1.2s;
}

.fade-out:nth-child(2) {
  animation-delay: 2.2s;
}

.fade-out:nth-child(1) {
  animation-delay: 3.0s;
}

     .nav-list.open{
     position:fixed;
    top: 90px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-block:4rem;
    gap: 4.5rem;
 opacity:1;
    z-index:1;
    cursor-events:auto;
    background-color: #00000;
    animation:slideInLeft 1s ease-in ;
    padding-inline-start: 16px;
      visibility:visible;
        overflow:hidden
     }

@keyframes slideInLeft{
  from{
    transform:translateX(-300px)
  }
  to{
    transform:translateX(0)
  }
}
    }
 
 .nav-list li.animate {
        opacity:1;
        transform:translateX(0);
        pointer-events:auto;
        transition:opacity 1s;
         transition-delay:5s;
     }
.animate {
  animation: fadeInUp 0.5s ease-in-out forwards running;
  opacity:1;
}
.animate:nth-child(1) {
  animation-delay: 0.5s;
}

.animate:nth-child(2) {
  animation-delay: .8s;
}

.animate:nth-child(3) {
  animation-delay: 1.3s;
}

.animate:nth-child(4) {
  animation-delay: 1.9s;
}
.animate:nth-child(5) {
  animation-delay: 2.5s;
}

 @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.a_sign-up_btn{
 background:#425CE8;
    color:#fff;
     border:none;
    // border:1px soild #425CE8;
    border-radius:8px;
    // padding:10px 20px;
} 
.a_sign-in_btn{
background:transparent;
    color:#425CE8;
    border:1px solid #425CE8;
    border-radius:8px;
//    padding:10px 20px;
} 
}
@media (max-width:40em){
 .navbar{
    background:#f5f5f5;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:10px;
    transition:all 0.3s ease;
     position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:9vh;
z-index:3;

 
}
    .menu-toggle{
        display:flex;
             transition: max-height 0.6s ease-in, opacity 0.5s ease-in;
  animation: fadeInUp 0.6s ease-in-out forwards;
  // background: blue;
    // color: white;
    padding: 4px;
    border-radius: 4px;
    }
    .nav-list a{
    text-decoration:none;
    color:#fff;
    // display:flex;
}

   .a-cta{
  display:flex;
  gap:60px;
  align-self:baseline;
}
    .nav-list{
     position:fixed;
     display: flex;
  flex-direction:column;
    top: 63px;
    left: 0;
    width: 100%;
    height: 100vh;
    gap: 4.5rem;
    align-items: baseline;
    padding-block:4rem;
    background-color: #000000;
animation: fade-out 0.5s ease forwards;
animation-delay:2s;
    padding-inline-start: 16px;
    opacity:0;
    visibility:hidden;

    }
.{
    color:#fff;
}
.dropdown {
  position: relative;
  cursor: pointer;
//   padding-inline:22px;
  // display:flex;
  // align-items:center;
  gap:10px;
  font-family: 'Lato';
  
}

@keyframes fade-out{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
   
  }
}

@keyframes fadeInLeft{
    from{
        opacity:1
    }
    to{
        opacity:0;
    transfrom:translateX(-100%);
    }
}
.fade-out {
    -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 4s ease-in-out ;
  opacity:0;
}
.fade-out:nth-child(5) {
  animation-delay: 0.5s;
}
.fade-out:nth-child(4) {
  animation-delay: 0.8s;
}

.fade-out:nth-child(3) {
  animation-delay: 1.2s;
}

.fade-out:nth-child(2) {
  animation-delay: 2.2s;
}

.fade-out:nth-child(1) {
  animation-delay: 3.0s;
}

     .nav-list.open{
     position:fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-block:4rem;
    gap: 4.5rem;
 opacity:1;
    z-index:1;
    cursor-events:auto;
    background-color: #00000;
    animation:slideInLeft 1s ease-in ;
        padding-inline-start: 16px;
        visibility:visible;
        overflow:hidden
     }

@keyframes slideInLeft{
  from{
    transform:translateX(-300px)
  }
  to{
    transform:translateX(0)
  }
}
    }
 
 .nav-list li.animate {
        opacity:1;
        transform:translateX(0);
        pointer-events:auto;
        transition:opacity 1s;
         transition-delay:5s;
     }
.animate {
  animation: fadeInUp 0.5s ease-in-out forwards running;
  opacity:1;
}
.animate:nth-child(1) {
  animation-delay: 0.5s;
}

.animate:nth-child(2) {
  animation-delay: .8s;
}

.animate:nth-child(3) {
  animation-delay: 1.3s;
}

.animate:nth-child(4) {
  animation-delay: 1.9s;
}
.animate:nth-child(5) {
  animation-delay: 2.5s;
}

 @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.a_sign-up_btn{
 background:#425CE8;
    color:#fff;
     border:none;
    // border:1px soild #425CE8;
    border-radius:8px;
    // padding:10px 20px;
} 
.a_sign-in_btn{
background:transparent;
    color:#425CE8;
    border:1px solid #425CE8;
    border-radius:8px;
//    padding:10px 20px;
}

}

`;
export default Anime;
