import React from "react";
import styled from "styled-components";
const Data = () => {
	return (
		<DataWrapper>
			<section className="Questions">
				<ul>
					<p>
						<li className="ques">
							What programs does the School of Software offer?
						</li>
						<p style={{ color: "#A1A1A1" }}>
							The School of Software offers a range of programs in areas such as
							full-stack web development, mobile app development, data science
							and analytics, software engineering, and DevOps and cloud
							computing. Our programs are designed to provide students with the
							skills and knowledge needed to succeed in the software industry.
						</p>
					</p>
					<li className="ques">
						What are the prerequisites for enrolling in the School of Software?
					</li>
					<li className="ques">
						Is the School of Software suitable for beginners with no prior
						coding experience?
					</li>
					<li className="ques">
						Are the programs at the School of Software available online or
						in-person?
					</li>
					<li className="ques">
						How long do the programs at the School of Software typically last?
					</li>
					<li className="ques">
						{" "}
						Can I pursue multiple programs simultaneously at the School of
						Software?
					</li>
					<li className="ques">
						Are there any scholarships or financial aid options available for
						students?
					</li>
					<li className="ques">
						How does the School of Software support job placement or career
						services?
					</li>
					<li className="ques">
						What is the student-to-faculty ratio at the School of Software?
					</li>
					<li className="ques">
						Are the instructors at the School of Software industry professionals
						or academicians?
					</li>
					<li className="ques">
						Can I get hands-on experience or work on real-world projects during
						the programs?
					</li>
					<li className="ques">
						Is there any flexibility in the program schedules for working
						professionals?
					</li>
					<li className="ques">
						Does the School of Software offer any post-graduate support or
						alumni resources?
					</li>
					<li className="ques">
						What technology and resources are available for students at the
						School of Software?
					</li>
					<li className="ques">
						How does the School of Software stay up to date with the latest
						industry trends and technologies?
					</li>
					<li className="ques">
						Is there any flexibility in the program schedules for working
						professionals?
					</li>
				</ul>
			</section>
		</DataWrapper>
	);
};
const DataWrapper = styled.div`
	.Questions {
		padding-inline: 30px;
	}
	.ques {
		list-style: disc;
		color: black;
		padding-block: 10px;
	}
`;
export default Data;
