import React from "react";
import Courses from "./Courses";
import styled from "styled-components";

const School = () => {
	return (
		<SchoolWrapper>
			<div className="sch-txt">
				<h1 className="sch-head-txt">
					Discover a world of possibilities with us today!
				</h1>
				<p style={{ lineHeight: "2.4em" }}>
					Our aim is to assist you in embarking on a transformative learning
					journey towards shaping your future. Choose from one of our four
					schools and kickstart your journey into a world of possibilities
					today.
				</p>
			</div>

			<Courses />
			{/* <section></section> */}
		</SchoolWrapper>
	);
};
const SchoolWrapper = styled.div`
	margin-top: 80px;
	@media screen and (min-width: 768px) and (max-width: 1024px){
	.sch-head-txt {
			font-weight: bold;
			font-size: 38px;
			padding-block:20px;
		}
	}
	@media screen and (min-width: 40em) {
		.sch-txt {
			margin-top: 50px;
			padding: 20px 80px;
			
		}
		.sch-
		// .sch-text h1,p{
		//   line-height:2.4em;
		// }
	}
	@media screen and (max-width: 40em) {
		.sch-txt {
			margin-top: 50px;
			padding: 20px 10px;
		}
		.sch-head-txt {
			font-weight: bold;
			font-size: 28px;
			padding-block:20px;
		}
	}
`;
export default School;
