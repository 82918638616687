import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QA = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [courses, setCourses] = useState("");

  const questionHandler = async (e) => {
    e?.preventDefault();
    if (email === "") {
      return;
    }
    try {
      const response = await fetch(
        "https://gwc-tech-school-africa.onrender.com/api/v1/news-letters",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            // courses: courses,
            // phone: phone,
            courses: courses,
          }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        // console.log(data);
        //    fetchData();
        toast.success("email successfully added");
      } else {
        toast.error(`${data.error}`);
        // throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      // throw error;
    }
  };
  return (
    <QAs>
      <section className="qa">
        <article className="course-section">
          <div className="h4">Not Sure What To Learn? We Can Help</div>
          <p className="help-intro">
            The tech industry is constantly evolving, and new skills are always
            in demand. If you're not sure what to learn, we can help you figure
            it out. We have career counselors who can help you assess your
            skills and interests. We also have a variety of online resources,
            including courses, tutorials, and articles.
          </p>
          <form className="inputs" onSubmit={questionHandler}>
            <input
              placeholder="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
            <input
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <select
              className="qselect"
              onChange={(e) => {
                setCourses(e.target.value);
              }}
              required
            >
              <option
                className="option"
                onChange={(e) => {
                  setCourses(e.target.value);
                }}
                required
              >
                Courses you like
              </option>
              <option
                className="option"
                onChange={(e) => {
                  setCourses(e.target.value);
                }}
                required
              >
                Schoool of Software
              </option>
              <option
                className="option"
                onChange={(e) => {
                  setCourses(e.target.value);
                }}
                required
              >
                Schoool of Product
              </option>
              <option
                className="option"
                onChange={(e) => {
                  setCourses(e.target.value);
                }}
                required
              >
                Schoool of Data
              </option>
              <option
                className="option"
                onChange={(e) => {
                  setCourses(e.target.value);
                }}
                required
              >
                Schoool of Communication
              </option>
            </select>

            <button className="q-btn" type="submit">
              Submit
            </button>
          </form>
        </article>

        <article>
          <p className="starting">Starting With GWC Tech School?</p>

          <section className="selection">
            <div className="course-selection">
              <a href="/" className="number">
                <span>1 </span>
              </a>
              <div
                href="/"
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Select Course</p>
                  <p className="course-details">
                    The first step is to select the right course for you. There
                    are many different courses available, so it is important to
                    choose one that is aligned with your interests and career
                    goals.
                  </p>
                </span>
              </div>
            </div>
            <div className="course-selection">
              <a href="/" className="number">
                <span>2</span>
              </a>
              <div
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Watch Video</p>
                  <p className="course-details">
                    Once you have selected a course, you should watch the
                    introduction video. This video will give you an overview of
                    the course and what you will learn. It will also help you
                    decide if the course is right for you.
                  </p>
                </span>
              </div>
            </div>
            <div className="course-selection">
              <a href="/" className="number">
                <span>3</span>
              </a>

              <div
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Enroll for Course</p>
                  <p className="course-details">
                    Once you have watched the introduction video and decided
                    that the course is right for you, you can enroll. You can do
                    this online or by contacting the school.
                  </p>
                </span>
              </div>
            </div>
          </section>
          <section className="selection">
            <div className="course-selection">
              <a href="/" className="number">
                <span>4</span>
              </a>
              <div
                href="/"
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Attend Classes</p>
                  <p className="course-details">
                    The next step is to attend classes. This is where you will
                    learn the skills that you need for your career. Be sure to
                    attend all of your classes and participate in the
                    discussions.
                  </p>
                </span>
              </div>
            </div>
            <div className="course-selection">
              <a href="/" className="number">
                <span>5</span>
              </a>
              <div
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Get Certified</p>
                  <p className="course-details">
                    Once you have completed the course, you can get certified.
                    This will show employers that you have the skills and
                    knowledge that they are looking for. You can get certified
                    by taking an exam or by completing a portfolio.
                  </p>
                </span>
              </div>
            </div>
            <div className="course-selection">
              <a href="/" className="number">
                <span>6</span>
              </a>

              <div
                className="ring"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <span
                  className="content"
                  data-aos="zoom-out-up"
                  data-aos-duration="3500"
                >
                  <p>Get a Job</p>
                  <p className="course-details">
                    Once you are certified, you can start looking for a job.
                    There are many different jobs available in the tech
                    industry, so you should be able to find one that is right
                    for you. You can use the school's career services to help
                    you find a job.
                  </p>
                </span>
              </div>
            </div>
          </section>
          <div className="container" style={{ cursor: "pointer" }}>
            {" "}
            <a href="apply.gwctechschoolafrica.com" id="btn">
              Enroll now
            </a>
          </div>
        </article>
        <ToastContainer />
      </section>
    </QAs>
  );
};
const QAs = styled.div`
  a {
    text-decoration: none;
  }
  @media screen and (min-width: 40em) {
    .qa {
      margin-block: 100px;
    }

    .course-section {
      margin-top: 30px;
      background: #425ce8;
      // height:30vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      padding: 40px 100px;
    }

    .q-btn {
      // padding:10px;
      font-size: 14px;
      line-height: 25px;
      background: #425ce8;
      color: white;
      border-radius: 15px;
      padding: 10px 40px;
      border: 1px solid #fff;
    }
    .course-section .h4 {
      color: #fff;
      font-size: 28px;
      padding: 20px;
    }
    .help-intro {
      // text-align:center;
      line-height: 2.8em;
      font-size: 14px;
      color: #fff;
    }
    input::placeholder {
      color: #fff;
    }

    input,
    select {
      padding: 15px 40px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
      // width:16vw;
      border-radius: 12px;
    }
    // input{
    //     color:red;
    // }

    .inputs {
      display: flex;
      gap: 80px;
      justify-content: space-between;
      color: #fff;
    }
    .starting {
      font-size: 36px;
      padding: 20px 90px;
    }

    .selection {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-block: 20px;
    }
    .course-selection {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
    .number {
      background: #425ce8;
      border: 1px solid #425ce8;
      padding: 6px 12px;
      border-radius: 50%;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
    }
    .ring {
      display: flex;
      flex-direction: column;

      border: 1px solid #425ce8;
      align-item: center;
      justify-content: center;
      border-radius: 50%;
      height: 200px;
      width: 200px;
    }

    .content {
      text-align: center;
    }

    .course-details {
      font-size: 10px;
      text-align: center;
      padding: 2px 20px;
    }
    .container {
      text-align: center;
      padding-block: 40px;
      cursor: pointer;
    }
    #btn {
      font-size: 14px;
      line-height: 25px;
      background: #425ce8;
      color: white;
      border-radius: 15px;
      padding: 15px;
      border: 1px solid #fff;
      cursor: pointer;
    }
  }

  //tablet

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .course-section {
      margin-top: 30px;
      background: #425ce8;
      // height:30vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      padding: 40px 10px;
    }

    .inputs {
      display: grid;
      // flex-wrap:wrap;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
      place-content: center;
      justify-content: space-between;
      color: #fff;
    }
    input,
    select {
      padding: 20px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
      border-radius: 12px;
    }
  }
  @media screen and (max-width: 40em) {
    .qa {
      margin-block: 100px;
    }
    .starting {
      font-size: 20px;
      /* padding: 17px 11px; */
      padding-block: 20px;
      text-align: center;
    }
    .course-section {
      margin-top: 30px;
      background: #425ce8;
      // height:30vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      padding: 40px 10px;
    }
    .q-btn {
      // padding:10px;
      font-size: 14px;
      line-height: 25px;
      background: #425ce8;
      color: white;
      border-radius: 15px;
      padding: 10px 40px;
      border: 1px solid #fff;
      width: 60vw;
      align-self: center;
      cursor: pointer;
    }
    .course-section .h4 {
      color: #fff;
      font-size: 24px;
      padding: 20px;
    }
    .help-intro {
      // text-align:center;
      line-height: 2.8em;
      font-size: 14px;
      color: #fff;
    }
    input::placeholder {
      color: #fff;
    }

    input {
      padding: 15px 40px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
      width: 60vw;
      border-radius: 12px;
    }

    .qselect {
      padding: 20px 10px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
      // width:60vw;
      border-radius: 12px;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: space-between;
      color: #fff;
      align-self: center;
    }

    .selection {
      display: grid;
      // grid-template-columns:repeat(3, 1fr);
      padding-block: 20px;
      gap: 25px;
    }
    .course-selection {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
    .number {
      background: #425ce8;
      border: 1px solid #425ce8;
      padding: 6px 12px;
      border-radius: 50%;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
    }
    .ring {
      display: flex;
      flex-direction: column;

      border: 1px solid #425ce8;
      align-item: center;
      justify-content: center;
      border-radius: 50%;
      height: 200px;
      width: 200px;
    }
    .starting {
      font-size: 20px;
      /* padding: 17px 11px; */
      padding-block: 20px;
      text-align: center;
    }
    .content {
      text-align: center;
    }

    .course-details {
      font-size: 10px;
      text-align: center;
      padding: 2px 20px;
    }
    .container {
      text-align: center;
      padding-block: 40px;
    }
    #btn {
      font-size: 14px;
      line-height: 25px;
      background: #425ce8;
      color: white;
      border-radius: 15px;
      padding: 15px;
      border: 1px solid #fff;
      cursor: pointer;
    }
  }
`;
export default QA;
