import React from "react";
import vid from "./Image/videoplayback.mp4";
import thum from "./Image/Rectangle 43.png";
import second from "./Image/Rectangle 44.png";
import third from "./Image/Rectangle 45.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

const VideoPlayer = () => {
  return (
    <VidWrapper>
      <section className="videos-div">
        <span className="intro-vid">Watch Introductory Videos</span>
        <span className="intro-vid_txt">
          Our introduction videos give you a brief overview of our courses and
          what you will learn. They are a great way to learn more about our
          programs and to decide if they are right for you.
        </span>

        <article className="video-section">
          <div>
            <video
              autoPlay
              loop
              controls
              className="vid"
              volume={0.5}
              src={vid}
              type="video/mp4"
              poster={thum}
            />
            {/* <source src="./Image/videoplayback.mp4" type="video/mp4" /> */}
            {/* Your browser does not support the video tag. */}
            {/* </video> */}
            <p className="pointer">
              <span>Introduction To Data Management</span> <span>05:00</span>
            </p>
          </div>
          <div>
            <video
              autoPlay
              loop
              controls
              className="vid"
              volume
              poster={second}
            >
              <source src="video.mp4" type="video/mp4" />
            </video>
            <p className="pointer">
              <span>Software Engineering for beginners</span> <span>05:00</span>
            </p>
          </div>
          <div className="">
            <video controls className="vid" poster={third}>
              <source src="video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <span className="pointer">
              <p>Product Design Introduction</p> <p>05:00</p>
            </span>
          </div>
        </article>

        <div style={{ alignSelf: "center", paddingBlock: "20px" }}>
          <Link
            to="https://youtube.com/@gwctechschoolafrica?si=AUHnuHc5tooobXX7"
            className="vid-btn"
          >
            Check other Videos
          </Link>
        </div>
      </section>
    </VidWrapper>
  );
};

const VidWrapper = styled.div`
  @media screen and (min-width: 40em) {
    .videos-div {
      margin-block: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //   padding-inline-start:60px;
    }
    // .video-wrapper{
    //     display:flex;
    //     flex-direction:column;
    // }
    .video-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // display:flex;
      gap: 30px;
      justify-content: center;
      padding-block: 20px;
      /* align-self: ; */
      place-items: center;
      place-content: center;
      padding-inline-start: 60px;
    }
    .pointer {
      display: flex;
      // gap:30px;
      justify-content: space-between;
    }
    .intro-vid {
      font-size: 30px;
      font-weight: bold;
      padding-block: 20px;
      padding-inline-start: 60px;
      font-weight: 600;
    }
    .intro-vid_txt {
      padding-block: 20px;
      padding-inline-start: 60px;
    }
    .vid {
      width: 420px;
      height: 300px;
    }
    .vid-btn {
      //   align-self:center;
      background: #425ce8;
      color: #fff;
      // border:1px soild #425CE8;
      border: none;
      border-radius: 8px;
      padding: 15px 20px;
      margin: auto 0;
    }
  }
  a {
    text-decoration: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .videos-div {
      margin-block: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //   padding-inline-start:60px;
    }
    .video-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      justify-content: center;
      padding-block: 20px;
      align-self: baseline;
      padding-inline: 4%;
    }
  }
  @media screen and (max-width: 40em) {
    .videos-div {
      margin-block: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //   padding-inline-start:60px;
    }

    .video-section {
      display: grid;
      gap: 30px;
      padding-block: 20px;
      place-items: center;
    }
    .pointer {
      display: flex;
      // gap:30px;
      justify-content: space-between;
    }
    .vid-btn {
      //   align-self:center;

      background: #425ce8;
      color: #fff;
      // border:1px soild #425CE8;
      border: none;
      border-radius: 8px;
      padding: 20px;
      // margin:auto 0;
    }

    .intro-vid {
      font-size: 25px;
      padding-block: 10px;
      padding-inline-start: 20px;
      font-weight: 600;
    }
    .intro-vid_txt {
      padding-block: 10px;
      font-size: 13px;
      padding-inline-start: 20px;
      line-height: 2.2em;
    }
    .vid {
      width: 94vw;
      // height:300px;
    }
    .pointer span {
      font-size: 12px;
    }
  }
`;
export default VideoPlayer;
