import React from "react";
import fr1 from "./Image/Frame 4.png";
import fr2 from "./Image/Frame 5.png";
import fr3 from "./Image/Frame 6.png";
import fr4 from "./Image/Frame 7.png";
import styled from "styled-components";
import Program from "./Program";
import grp from "./Image/tech.png";
import Testimonial from "./WaitingPage/Testimonials";
// import About from "./About";
// import About from "./About";

const AboutUsPage = () => {
	return (
		<AbtWrapper style={{ marginBlock: "80px" }}>
			<section>
				<h1 className="abt-title" style={{ marginTop: "60px" }}>
					About Us
				</h1>
				<section className="image-section">
					<img
						src={fr1}
						alt=""
						data-aos="fade-right"
						data-aos-offset="300"
						data-aos-duration="1000"
						data-aos-easing="ease-in"
					/>
					<img src={fr2} alt="" data-aos="fade-up" data-aos-duration="3000" />
					<img
						src={fr3}
						alt=""
						data-aos="fade-down"
						data-aos-offset="300"
						data-aos-easing="linear"
						data-aos-duration="1500"
					/>
					<img
						src={fr4}
						alt=""
						data-aos="fade-left"
						// data-aos-offset="300",
						// data-aos-anchor="#example-anchor"
						data-aos-offset="500"
						data-aos-duration="500"
					/>
				</section>
				<section className="prog-intro">
					<article className=" prog-txt">
						<p
							className="abt-prog"
							data-aos="zoom-in-right"
							data-aos-duration="2000">
							Our programs are designed to give you the skills you need to
							succeed in today's tech-driven world. We offer a variety of
							programs to choose from, including computer science, engineering,
							and data science. Our faculty are experts in their field and are
							passionate about teaching. Our students are bright, motivated, and
							eager to learn. We offer a supportive and collaborative learning
							environment. We are committed to helping our students succeed.
						</p>
						<div className="d-sec">
							<img src={grp} alt="" />
							{/* <p>Tech skills for dream job</p> */}
							<div></div>
						</div>
					</article>
				</section>
			</section>
			<Program />
			<Testimonial />
		</AbtWrapper>
	);
};
const AbtWrapper = styled.div`
	a {
		text-decoration: none;
	}
	@media screen and (min-width: 40em) {
		.image-section {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			// height:85px;
			// width:80%;
		}
		.image-section img {
			height: auto;
			width: 100%;
		}
		.abt-prog {
			line-height: 2.8em;
			padding-inline-start: 30px;
			padding-block-end: 30px;
			// text-align: justify;
			// padding-inline: 20px;
		}
		.prog-intro {
			padding: 10px 50px;
			// display:grid;
			//  grid-template-columns:repeat(2,1fr);
			//  grid-gap:350px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.abt-title {
			padding-block: 20px;
			padding-inline: 90px;
			font-size: 36px;
			font-weight: 600;
			font-family: Lato;
			margin-top: 60px;
		}
		.prog-txt {
			display: flex;

			gap: 120px;
			justify-content: space-between;
			// width: 50vw;
		}
		.d-sec img {
			margin-top: 10px;
			//    height:85px
			height: 18%;
			width: 100%;
		}
		.prog-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #425ce8;
			color: #fff;
			border: none;
			// border:1px solid #425CE8;
			border-radius: 8px;
			padding: 15px;
			margin-block: 40px;
			cursor: pointer;
			margin-inline-start: 25px;
			margin-inline: 42%;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.prog-intro {
			padding: 10px 20px;
			// display:grid;
			//  grid-template-columns:repeat(2,1fr);
			//  grid-gap:350px;
			display: flex;
			justify-content: space-between;
		}
		.d-sec img {
			margin-top: 10px;
			//    height:85px
			height: 8%;
			width: 100%;
		}
		.prog-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			background: #425ce8;
			color: #fff;
			border: none;
			// border:1px solid #425CE8;
			border-radius: 8px;
			padding: 15px;
			margin-block: 40px;
			cursor: pointer;
			margin-inline: 30%;
			// width: 30%;
		}
	}

	@media screen and (max-width: 40em) {
		.prog-intro,
		.prog-txt {
			display: flex;
			flex-direction: column;
			// width: 50vw;
		}
		.abt-title {
			padding-block: 40px;
			// padding-inline: 30px;
			text-align: center;
			font-size: 36px;
			font-weight: 600;
			margin-top: 60px;
		}
		.image-section {
			display: grid;
			place-items: center;
			grid-template-columns: repeat(2, 1fr);
			// height:85px;
			// width:80%;
			// display: flex;
			// flex-direction: column;
			// justify-content:center
			// align-items: center;
		}
		.abt-prog {
			line-height: 2.3em;
			text-align: justify;
			padding-inline: 20px;
			font-size: 13px;
			padding-block-end: 20px;
		}
		.d-sec img {
			visibility: hidden;
			//    height:85px
		}
		.image-section img {
			// height:60vh;
			width: 50vw;
		}
		.d-sec {
			display: none;
		}
		.prog-btn {
			background: #425ce8;
			color: #fff;
			border: none;
			// border:1px soild #425CE8;
			border-radius: 8px;
			padding: 10px;
			margin-block: 20px;
			margin-inline: 20px;
			cursor: pointer;
			align-self: center;
		}
	}
`;
export default AboutUsPage;
