import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { TfiMedallAlt } from "react-icons/tfi";
import { LuPanelTopInactive } from "react-icons/lu";
import { AiTwotoneStar } from "react-icons/ai";
import software from "../Image/software.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Courses = () => {
	return (
		<CourseWrapper>
			<section className="course-section">
				<article className="course-row">
					<div>
						<img src={software} alt="" className="course-image" />
						<ul className="course-details">
							<li className="duration">
								<AiOutlineCalendar className="c-icon" /> 7-9 Months
							</li>
							<li className="topics">
								{" "}
								<LuPanelTopInactive className="c-icon" />
								10 Topics
							</li>
							<li className="classes">
								{" "}
								<TfiMedallAlt className="c-icon" /> Classes
							</li>
						</ul>
						<p className="sch-title">School of software</p>
						<p className="course-pr">
							<span>
								$200
								<b className="faint"> (you can pay in installments) </b>
							</span>{" "}
							<Link to="/software" className="lrn-btn">
								Learn More
							</Link>
						</p>
						<div classNam id="input" className="point">
							<p
								className="flex items-center"
								style={{ display: "flex", gap: "5px" }}>
								<span style={{ paddingInline: "2px" }}>5.0 </span>
								<b className="star-icon">
									<AiTwotoneStar /> <AiTwotoneStar />
									<AiTwotoneStar />
									<AiTwotoneStar />
									<AiTwotoneStar />
								</b>{" "}
							</p>

							<span>(841)</span>
						</div>
					</div>
				</article>
				<article className="course-row">
					<div>
						<img src={software} alt="" className="course-image" />
						<ul className="course-details">
							<li className="duration">
								<AiOutlineCalendar className="c-icon" /> 7-9 Months
							</li>
							<li className="topics">
								{" "}
								<LuPanelTopInactive className="c-icon" />
								10 Topics
							</li>
							<li className="classes">
								{" "}
								<TfiMedallAlt className="c-icon" /> Classes
							</li>
						</ul>
						<p className="sch-title">School Of Design</p>
						<p className="course-pr">
							<span>
								$200<b className="faint"> (you can pay in installments) </b>
							</span>
							<Link to="/software" className="lrn-btn">
								Learn More
							</Link>
						</p>
						<h6 classNam id="input" className="point">
							5.0{" "}
							<b className="star-icon">
								<AiTwotoneStar /> <AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
							</b>{" "}
							(841)
						</h6>
					</div>
				</article>
			</section>
			<section className="course-section">
				<article className="course-row">
					<div>
						<img src={software} alt="" className="course-image" />
						<ul className="course-details">
							<li className="duration">
								<AiOutlineCalendar className="c-icon" /> 7-9 Months
							</li>
							<li className="topics">
								{" "}
								<LuPanelTopInactive className="c-icon" />
								10 Topics
							</li>
							<li className="classes">
								{" "}
								<TfiMedallAlt className="c-icon" /> Classes
							</li>
						</ul>
						<p className="sch-title">School Of Communication</p>
						<p className="course-pr">
							<span>
								{" "}
								$200
								<b className="faint"> (you can pay in installments) </b>
							</span>{" "}
							<Link to="/software" className="lrn-btn">
								Learn More
							</Link>
						</p>
						<h6 classNam id="input" className="point">
							5.0{" "}
							<b className="star-icon">
								<AiTwotoneStar /> <AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
							</b>{" "}
							(841)
						</h6>
					</div>
				</article>
				<article className="course-row">
					<div>
						<img src={software} alt="" className="course-image" />
						<ul className="course-details">
							<li className="duration">
								<AiOutlineCalendar className="c-icon" /> 7-9 Months
							</li>
							<li className="topics">
								{" "}
								<LuPanelTopInactive className="c-icon" />
								10 Topics
							</li>
							<li className="classes">
								{" "}
								<TfiMedallAlt className="c-icon" /> Classes
							</li>
						</ul>
						<p className="sch-title">School Of Data</p>
						<p className="course-pr">
							<span>
								$200
								<b className="faint"> (you can pay in installments) </b>
							</span>{" "}
							<Link to="/software" className="lrn-btn">
								Learn More
							</Link>
						</p>

						<h6 classNam id="input" className="point">
							5.0{" "}
							<b className="star-icon">
								<AiTwotoneStar /> <AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
								<AiTwotoneStar />
							</b>{" "}
							(841)
						</h6>
					</div>
				</article>
			</section>
		</CourseWrapper>
	);
};

const CourseWrapper = styled.div`
	@media screen and (min-width: 40em) {
		.course-section {
			display: flex;
			grid-template-columns: repeat(2, 1fr);
			gap: 40px;
			justify-content: center;
			align-items: center;
			font-family: "Lato";
			padding: 0 30px;
			margin-block-end: 30px;
		}
		.course-row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			font-family: "Lato";
		}
		.course-image {
			height: 390px;
			/* width: 200;   */
		}

		.course-details {
			display: flex;
			justify-content: space-between;
			/* gap: 220px; */
			align-items: center;
			list-style: none;
			margin: 0;
			padding: 0;
			font-family: "Lato";
		}
		li {
			list-style: none;
		}
		.duration,
		.topics,
		.classes {
			display: flex;
			align-items: center;
			gap: 10px;
			padding-block: 7px;
			font-family: "Lato";
		}

		.faint {
			color: #33333376;
			font-size: 14px;
			font-family: "Lato";
		}

		.star-icon {
			display: flex;
			align-items: center;
			color: gold;
			font-family: "Lato";
		}

		.point {
			display: flex;
			align-items: center;
			align-items: center;
			font-family: "Lato";
			gap: 10px;
		}
		.course-pr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-items: center;
			font-family: "Lato";
		}

		.sch-title {
			font-weight: 550;
			font-size: 20px;
			padding-block: 20px;
			align-items: center;
			font-family: "Lato";
		}

		.lrn-btn {
			border: 1px solid #425ce8;
			align-items: center;
			font-family: "Lato";
			color: white;
			background-color: #425ce8;
			border-radius: 8px;
			text-decoration: none;
			padding: 8px 20px;
			font-size: 12px;
			align-items: center;
			font-family: "Lato";
		}
		.c-icon {
			color: #e08d79;
		}
	}
	@media screen and (max-width: 40em) {
		.course-image {
			/* height: 29vh; */
			width: 100%;
		}

		.course-row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			font-family: "Lato";
			padding-inline: 10px;
			padding-block: 20px;
		}
		.course-details {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.duration,
		.topics,
		.classes {
			display: flex;
			align-items: center;
			gap: 6px;
			padding-block: 7px;
			font-family: "Lato";
			font-size: 14px;
		}
		.course-pr {
			display: flex;
			flex-direction: column;
			/* justify-content: space-between; */
			/* align-items: center; */
			/* align-items: center;     */
			gap: 20px;
			font-family: "Lato";
		}
		.medium {
			width: 90vw;
			height: 1.7vh;
		}
		.mission-img {
			width: 59vw;
			/* height: 3vh;   */
		}

		.faint {
			color: #33333376;
			font-size: 14px;
			font-family: "Lato";
		}

		.star-icon {
			display: flex;
			align-items: center;
			color: gold;
			font-family: "Lato";
		}

		.point {
			display: flex;
			gap: 5px;
			// justify-content: space-between;
			align-items: center;
			align-items: center;
			font-family: "Lato";
			padding-block: 10px;
		}
	}
`;

export default Courses;
