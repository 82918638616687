import React from "react";
import styled from "styled-components";
import short from "./Image/short.png";
import medium from "./Image/long.png";
import AboutUs from "./WaitingPage/AboutUs";
// import medium from "./Image/long.png"

const Program = () => {
	return (
		<ProgramWrapper>
			<section>
				<article className="prog-intro">
					<div className="underlined">
						<img src={short} alt="" className="v-short" />
						<p className="vshort-head">Our programs</p>
						<p className="desc-details">
							Welcome to GWC Tech School Africa, where innovation meets
							education. As a dynamic and innovative educational institution, we
							offer cutting-edge training programs in technology and computer
							science. Our goal is to equip individuals with the skills and
							knowledge they need to thrive in today's rapidly evolving tech
							industry.
						</p>
					</div>

					<div className="underlined">
						<img src={medium} alt="" className="medium" />
						<p className="w-head">Our Commitment to Quality Education</p>
						<p className="desc-details">
							We at GWC Tech School Africa, we are committed to providing
							top-notch education that empowers individuals to become experts in
							the field of technology. Our experienced faculty members are
							dedicated to ensuring that each student receives personalized
							attention and support throughout their learning journey. With a
							focus on meeting the demands of the fast-paced, technology-driven
							world, we strive to deliver quality education that prepares our
							students for success
						</p>
					</div>
					<AboutUs />
				</article>
			</section>
		</ProgramWrapper>
	);
};
const ProgramWrapper = styled.div`
	@media screen and (min-width: 40em) {
		.prog-intro {
			display: flex;
			flex-direction: column;
			// padding-block-start: 50px;
			padding-block-end: 28px;
			font-family: "Lato";
		}
		.underlined {
			position: relative;
			padding-block: 20px;
			padding-inline: 30px;
			font-family: "Lato";
		}
		.underlined-head {
			position: relative;
			padding-block: 20px;
			padding-inline: 62px;
			font-family: "Lato";
		}
		.underlined img {
			position: relative;
		}

		.v-short {
			width: 11vw;
			height: 2vh;
		}
		.w-head {
			position: absolute;
			top: 1vh;
			z-index: 3;
			font-size: 22px;
			left: 3vw;
			font-weight: 600;
			font-family: "Lato";
			font-family: "Lato";
		}
		.sw-head {
			position: absolute;
			top: 10%;
			z-index: 3;
			font-size: 22px;
			/* line-height: .5rem; */
			left: 5%;
			font-family: "Lato";
			font-weight: 600;
		}

		.vshort-head {
			position: absolute;
			top: 2vh;
			z-index: 3;
			font-size: 23px;
			line-height: 0.5rem;
			/* left: 10%; */
			font-weight: 600;
			font-family: "Lato";
		}
		.desc-details {
			line-height: 2.8em;
			font-size: 16px;
			font-family: "Lato";
		}

		.medium {
			width: 28vw;
			height: 2.5vh;
		}
	}
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.vshort-head {
			position: absolute;
			top: 2vh;
			z-index: 3;
			font-size: 23px;
			line-height: 0.5rem;
			/* left: 10%; */
			font-weight: 600;
			font-family: "Lato";
		}
		.v-short {
			width: 20%;
			height: 2vh;
		}

		.medium {
			width: 49vw;
			height: 2.5vh;
		}
	}
	@media screen and (max-width: 40em) {
		.prog-intro {
			display: flex;
			flex-direction: column;
			padding-block-start: 50px;
			padding-block-end: 30px;
			font-family: "Lato";
			padding-inline: 10px;
			align-items: center;
		}
		.underlined {
			position: relative;
			/* padding-block: 20px; */
			padding-inline: 12px;
			font-family: "Lato";
			padding-block-end: 20px;
		}
		.underlined-head {
			position: relative;
			/* padding-block: 20px; */
			padding-inline: 12px;
			font-family: "Lato";
			margin: 30px 0;
		}
		.underlined img {
			position: relative;
		}
		.v-short {
			width: 37vw;
			height: 1.7vh;
		}

		.vshort-head {
			position: absolute;
			top: -1.2vh;
			z-index: 3;
			font-size: 18px;
			left: 5%;
			font-weight: 600;
			font-family: "Lato";
			font-family: "Lato";
		}
		.w-head {
			position: absolute;
			top: -0.8vh;
			z-index: 3;
			font-size: 16px;
			/* line-height: .5rem; */
			left: 7%;
			font-weight: 600;
			font-family: "Lato";
		}
		.sw-head {
			position: absolute;
			top: -130%;
			z-index: 3;
			font-size: 17px;
			font-weight: 600;
			/* line-height: .5rem; */
			left: 7%;
			font-family: "Lato";
		}

		.medium {
			// width: 53vw;
			height: 1.7vh;
		}
		.mission-img {
			width: 59vw;
			/* height: 3vh;   */
		}
		.a-head {
			position: absolute;
			font-weight: 600;
			top: -1.1vh;
			z-index: 3;
			font-size: 17px;
			/* line-height: .5rem; */
			left: 7%;
			margin: 0;
			font-family: "Lato";
		}
		.vission {
			position: relative;

			width: 31vw;
			height: 1.5vh;
		}
		.desc-details {
			line-height: 2.8em;
			font-size: 15px;
			font-family: "Lato";
		}

		.abt-us {
			margin-top: 40px;
		}

		.s-story {
			position: relative;
			width: 42vw;
			height: 1.7vh;
		}
	}
`;
export default Program;
