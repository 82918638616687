import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		// Scroll to the top when the pathname (route) changes
		window.scrollTo(0, 0);
	}, [pathname]);

	return null; // This is a non-rendering component
}

export default ScrollToTop;
