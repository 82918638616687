import React from "react";
import styled from "styled-components";
import sft from "./Image/sft.png";
import data from "./Image/Group 25.png";
import prdt from "./Image/monitor.png";
import comm from "./Image/communication.png";
// import { Link } from 'react-router-dom'

const Programs = () => {
	return (
		<Pro>
			<section className="programs">
				<p className="p-title">Our Programs</p>

				<main className="program-category">
					<section
						className="program-desc"
						data-aos="fade-right"
						data-aos-duration="2000">
						<img src={sft} alt="" />
						<span className="sch-title">School of Software</span>
						<span className="prog-details">
							The School of Software Engineering is a hypothetical program that
							would focus on training individuals in the field of software
							engineering..
						</span>
					</section>
					<section
						className="program-desc"
						data-aos="fade-down-right"
						data-aos-duration="3000">
						<img src={data} alt="" />
						<span className="sch-title">School of Data</span>
						<span className="prog-details">
							The School of Software Engineering is a hypothetical program that
							would focus on training individuals in the field of software
							engineering.
						</span>
					</section>
					<section
						className="program-desc"
						data-aos="fade-down-right"
						data-aos-duration="3000">
						<img src={prdt} alt="" />
						<span className="sch-title">School of Product</span>
						<span className="prog-details">
							The School of Software Engineering is a hypothetical program that
							would focus on training individuals in the field of software
							engineering..
						</span>
					</section>
					<section
						className="program-desc"
						data-aos="fade-down-right"
						data-aos-duration="2000">
						<img src={comm} alt="" />
						<span className="sch-title">School of communication</span>
						<span className="prog-details">
							The School of Software Engineering is a hypothetical program that
							would focus on training individuals in the field of software
							engineering..
						</span>
					</section>
				</main>
				<div className="container">
					<a href="apply.gwctechschoolafrica.com" id="btn">
						Enroll now
					</a>
				</div>
			</section>
		</Pro>
	);
};
const Pro = styled.section`
	a {
		text-decoration: none;
	}
	.sch-title {
		font-weight: 600;
		font-size: 22px;
	}
	@media screen and (min-width: 40em) {
		.programs {
			margin-top: 80px;
		}
		.program-desc {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			justify-content: center;
		}
		.program-desc img {
			height: 65px;
			width: 65px;
		}
		.prog-details {
			font-size: 12px;
			color: #333;
			line-height: 24px;
		}
		.container {
			text-align: center;
		}
		#btn {
			font-size: 14px;
			line-height: 25px;
			background: #425ce8;
			color: white;
			border-radius: 15px;
			padding: 15px;
			border: 1px solid #fff;
			cursor: pointer;
		}
		.p-title {
			font-size: 35px;
			padding-inline: 50px;
			padding-block: 30px;
			font-weight: 500;
			line-height: 46.8px;
		}
		.container {
			padding-block: 30px;
			cursor: pointer;
		}
		.program-category {
			// display:flex;
			// justify-content:space-between;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			padding: 20px 60px;
			place-items: center;
			grid-gap: 40px;
		}
	}

	@media screen and (max-width: 40em) {
		.program-category {
			display: flex;
			// justify-content: space-between;
			display: grid;
			// grid-template-columns: repeat(4, 1fr);
			padding: 20px 40px;
			place-items: center;
			grid-gap: 40px;
		}
		.program-desc {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			justify-content: center;
		}
		.program-desc img {
			height: 48px;
			width: 48px;
		}
		.prog-details {
			font-size: 12px;
			color: #333;
			line-height: 24px;
		}
		#btn {
			font-size: 14px;
			line-height: 25px;
			background: #425ce8;
			color: white;
			border-radius: 15px;
			padding: 10px 20px;
			border: 1px solid #fff;
			cursor: pointer;
		}

		.p-title {
			font-size: 25px;
			padding-inline: 17px;
			padding-block-start: 37px;
			font-weight: 600;
			text-align: center;
		}

		.container {
			text-align: center;
		}
	}
`;
export default Programs;
