import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Newsletter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleCheckboxChange = () => {
    setIsSubscribed(!isSubscribed);
  };

  const subscribeToNewsletter = async (e) => {
    e?.preventDefault();
    try {
      const response = await fetch(
        "https://gwc-tech-school-africa.onrender.com/api/v1/news-letters",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            phone: phone,
            location: location,
          }),
        }
      );

      const data = await response.json();

      if (isSubscribed && response.ok && data.success === true) {
        toast.success("Email successfully subscribed");
      } else if (!isSubscribed) {
        toast.error("Checkbox not checked. Subscription not submitted");
      } else if (isSubscribed && response.status === 500) {
        toast.error(
          "User with this email or phone number already exists. Email not successfully subscribed."
        );
      }
    } catch (error) {
      toast.error("Email not successfully subscribed. Check your details");
      console.error("Error:", error.message);
    }
  };

  return (
    <NewsletterWrapper>
      <section className="nl-holder">
        <article className="news-letter">
          <h2 className="nl-title">Subscribe To Our Newsletter</h2>
          <p className="nl-text">
            Stay up-to-date on the latest news and events from our school by
            subscribing to our newsletter. We will send you regular updates on
            our courses, events, and alumni. You will also receive exclusive
            discounts and offers.
          </p>
        </article>
        <article className="nlf-form">
          <form onSubmit={subscribeToNewsletter} className="news-letter_form">
            <input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="inp"
            />
            <div className="digit">
              <input
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="inp"
              />
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="inp"
              />
            </div>
            <div className="course-sec">
              <select
                className="select"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option>Software</option>
                <option>Data</option>
                <option>Product</option>
                <option>Communication</option>
              </select>
              <input
                placeholder="Location"
                onChange={(e) => setLocation(e.target.value)}
                className="inp"
              />
            </div>
            <div className="terms">
              <input
                type="checkbox"
                className="radio-button"
                checked={isSubscribed}
                onChange={handleCheckboxChange}
              />
              <p className="terms-text">
                I have read and understood the privacy policy, and I am aware of
                my right to withdraw my consent at any time. I also understand
                who the responsible persons are for my data processing.
              </p>
            </div>
            <p className="nsl-btn">
              <button type="submit" className="nl-btn">
                Subscribe
              </button>
            </p>
          </form>
        </article>
        <ToastContainer />
      </section>
    </NewsletterWrapper>
  );
};

const NewsletterWrapper = styled.div`
  .nl-holder {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #425ce8;
    padding: 40px;
  }

  .nl-title {
    font-size: 30px;
    color: #fff;
  }

  .nl-text {
    font-size: 13px;
    width: 60vw;
    line-height: 2.3em;
    color: #fff;
    padding-bottom: 20px;
  }

  .nlf-form {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #f5f5f5;
    height: auto;
    width: 32vw;
    top: 15%;
    right: 5%;
    padding: 25px 20px;
    border-radius: 8px;
  }
  .news-letter_form {
    display: grid;
    gap: 20px;
  }
  .inp,
  .select {
    border: 0.5px solid #425ce8;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
  }

  .terms {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .radio-button {
    margin-top: 15px;
    padding: 5px 10px;
  }

  .terms-text {
    color: #fff;
    font-size: 12px;
  }

  .nsl-btn {
    display: flex;
    justify-content: center;
    align-self: center;
    padding-block: 10px;
    cursor: pointer;
  }

  .nl-btn {
    align-self: center;
    background: #425ce8;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    cursor: pointer;
  }

  .digit,
  .course-sec {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .select {
    // width: 20vw;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .nl-holder {
      margin: 100px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      flex-direction: column;
      position: relative;
      background: #425ce8;
      padding: 20px;
    }

    .news-letter_form {
      display: grid;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
    }

    .nl-text {
      font-size: 13px;
      width: 50vw;
      line-height: 2.3em;
      color: #fff;
      padding-bottom: 20px;
    }

    .nlf-form {
      display: grid;
      position: absolute;
      background: #f5f5f5;
      height: auto;
      /* width: 50%; */
      top: 15%;
      right: 8%;
      padding: 25px 10px;
      border-radius: 8px;
    }

    .inp,
    .select {
      border: 0.5px solid #425ce8;
      border-radius: 8px;
      padding: 10px;
      /* width: 20vw; */
    }
    .nl-text {
      width: 40vw;
    }
  }

  @media screen and (max-width: 40em) {
    .nl-holder {
      margin: 100px auto;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #425ce8;
      padding: 40px;
    }

    .inp,
    .select {
      border: 0.5px solid #425ce8;
      border-radius: 8px;
      padding: 10px;
    }

    .nl-title {
      font-size: 30px;
      color: #fff;
    }

    .nl-text {
      font-size: 13px;
      width: 100%;
      line-height: 2.3em;
      color: #fff;
      padding-bottom: 20px;
    }

    .news-letter_form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
    }

    .nlf-form {
      display: flex;
      flex-direction: column;
      position: absolute;
      background: #f5f5f5;
      height: auto;
      width: 100%;
      top: 90%;
      right: 1%;
      left: -1%;
      // padding: 25px 20px;
      border-radius: 8px;
      // margin-bottom: 200px;
    }

    .terms {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }

    .radio-button {
      margin-top: 15px;
      padding: 5px 10px;
    }

    .terms-text {
      color: black;
      font-size: 12px;
    }

    .nsl-btn {
      align-self: center;
      padding-block: 20px;
      padding: 15px 20px;
      cursor: pointer;
    }

    .nl-btn {
      align-self: center;
      background: #425ce8;
      color: #fff;
      border: none;
      border: 1px solid #425ce8;
      border-radius: 8px;
      padding: 10px;
      padding: 15px 25px;
      cursor: pointer;
    }

    .digit,
    .course-sec {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;
    }

    .select {
      width: 100%;
    }

    .news-letter h2 {
      color: #fff;
    }
  }
  .terms-text {
    color: black;
    font-size: 12px;
  }
`;

export default Newsletter;
