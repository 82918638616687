import React, { useState } from "react";
import styled from "styled-components";
import GeneralQuestion from "./GeneralQuestion";
import Design from "./Design";
import Communication from "./Communication";
import Data from "./Data";
import Software from "./Software";
// import Software from '../School/Software';
// import { } from 'react-router-dom'

const Faq = () => {
	const [show, setShow] = useState(true);
	const [software, setSoftware] = useState(false);
	const [design, setDesign] = useState(false);
	const [dataQuestion, setDataQuestion] = useState(false);
	const [communication, setCommunication] = useState(false);
	return (
		<Fawrapper>
			<h1
				style={{
					marginTop: "85px",
					paddingInline: "20px",
					paddingBlock: "30px",
					fontWeight: "bold",
					fontSize: "30px",
				}}>
				Frequently Asked Questions.
			</h1>

			<ul className="question-tab">
				<li
					className="que-header"
					onClick={() => {
						setShow(true);
						setSoftware(false);
						setCommunication(false);
						setDesign(false);
						setDataQuestion(false);
					}}
					style={{
						color: show ? "blue" : "grey",
						borderBottom: show ? "1px solid blue" : "grey",
					}}>
					General Questions
				</li>

				<li
					className="que-header"
					onClick={() => {
						setSoftware(true);
						setShow(false);
						setCommunication(false);
						setDesign(false);
						setDataQuestion(false);
					}}
					style={{
						color: software ? "blue" : "grey",
						borderBottom: software ? "1px solid blue" : "grey",
					}}>
					Software Questions
				</li>
				<li
					className="que-header"
					onClick={() => {
						setShow(false);
						setSoftware(false);
						setCommunication(false);
						setDesign(true);
						setDataQuestion(false);
					}}
					style={{
						color: design ? "blue" : "grey",
						borderBottom: design ? "1px solid blue" : "grey",
					}}>
					Design Questions
				</li>

				<li
					className="que-header"
					onClick={() => {
						setShow(false);
						setSoftware(false);
						setCommunication(false);
						setDesign(false);
						setDataQuestion(true);
					}}
					style={{
						color: dataQuestion ? "blue" : "grey",
						borderBottom: dataQuestion ? "1px solid blue" : "grey",
					}}>
					{" "}
					Data Questions
				</li>
				<li
					className="que-header"
					onClick={() => {
						setShow(false);
						setSoftware(false);
						setCommunication(true);
						setDesign(false);
						setDataQuestion(false);
					}}
					style={{
						color: communication ? "blue" : "grey",
						borderBottom: communication ? "1px solid blue" : "grey",
					}}>
					Communication Questions
				</li>
			</ul>
			<hr className="line-breaker" />

			<section className="Questions">
				{show && <GeneralQuestion />}
				{software && <Software />}
				{design && <Design />}
				{dataQuestion && <Data />}
				{communication && <Communication />}
			</section>
		</Fawrapper>
	);
};
const Fawrapper = styled.div`
	@media screen and (min-width: 40em) {
		.que-header {
			// text-decoration:underline;
			border-bottom: 1px solid black;
		}
		.question-tab {
			display: flex;
			justify-content: space-between;
			padding-inline: 70px;
		}
		// .Questions{
		//   padding-inline:30px;
		// }
		// .ques{
		// list-style:disc;
		//   color:black;
		//   padding-block:10px;
		// }
		.line-breaker {
			margininline: 40px;
		}
	}

	@media screen and (max-width: 40em) {
		.que-header {
			// text-decoration:underline;
			border-bottom: 1px solid black;
		}
		.question-tab {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			flex-direction: column;
			-webkit-justify-content: space-between;
			gap: 20px;
			-ms-flex-pack: justify;
			justify-content: space-between;
			padding-inline: 20px;
		}
		.line-breaker {
			margin-inline: 17px;
		}
	}
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.question-tab {
			padding-inline: 22px;
		}
	}
`;
export default Faq;
