import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import profile from "../Image/Ellipse 55.png";
import { FaLinkedin, FaGithub, FaXTwitter } from "react-icons/fa6";
// import {  } from "react-icons/bs";

const Partner = () => {
	return (
		<ProfileWrapp>
			<h1 className="partners">Our Partners</h1>
			<p className="partners-intro">
				Here are some of the most amazing people supporting our journey to bring
				Tech Education to Africa
			</p>
			<section className="profile-holder">
				<article className="teams">
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
				</article>
				<article className="teams">
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
					<div className="profile">
						<img src={profile} alt="" />
						<h6 className="social_links">
							<a href="/">
								<FaLinkedin />
							</a>
							<a href="/">
								<FaGithub />
							</a>
							<a href="/">
								<FaXTwitter />
							</a>
						</h6>
						<p className="profile-details">
							Here are some of the most amazing people supporting our journey to
							bring Tech Education to Africa
						</p>
					</div>
				</article>
			</section>
			<p className="partner-action">
				Do you want to Partner with GWC Tech School Africa?{" "}
				<Link to="/partnerform"> Click Here</Link>
			</p>
		</ProfileWrapp>
	);
};

const ProfileWrapp = styled.div`
.partners{
	    margin-top: 80px;
    padding: 20px 40px;
	font-size: 38px;
	font-weight: bold;
}
.social_links{
display:flex;
flex-direction:row;
gap:10px;
padding-block:20px;
}
.partner-action{
	// padding:20px 40px;
	text-align:center;
	padding-block:5px;
}
.partners-intro{
	padding:20px 40px;
}
	.profile-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify:content:space-between;
		gap:40px;
		padding:20px 40px;
	}
	.profile img, p,{
		padding-block:20px;
	}

	@media screen and (min-width:40em){
.teams {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		place-items: center;
		gap: 40px;
	}
	}
	.profile {
		display: flex;
		flex-direction: column;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	}
	.profile img{
		border:1px solid red;
		border-radius:8px;
		padding:20px;
		
	}
	@media screen and (max-width:40em){
	.teams {
		display: grid;
		// grid-template-columns: repeat(4, 1fr);
		place-items: center;
		gap: 20px;
	}}
	.profile-details{
		text-align:center;
		font-size:13px;
	}

	@media screen and (min-width: 768px) and (max-width: 1024px) {
	.teams {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        place-items: center;
        gap: 20px;
    }
}
`;
export default Partner;
