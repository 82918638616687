import React from "react";
import Header from "./Header";
import About from "./About";
import Programs from "./Programs";
import QA from "./Q&A";
// import Footer from './WaitingPage/Footer'
import VideoPlayer from "./Video";
import Newsletter from "./Newsletter";
import Opportunities from "./Opportunities";
// import Blog from "./Blog";

const LandingPage = () => {
	return (
		<>
			<Header />
			<About />
			<Programs />
			<QA />
			<VideoPlayer />
			<Newsletter />
			<Opportunities />
			{/* <Blog /> */}
			{/* <Footer/> */}
		</>
	);
};

export default LandingPage;
