import { React } from "react";
// import { Link } from "react-router-dom";
import {
	// Paper,
	Container,
	Typography,
	Box,
	TextField,
	// Select,
	InputLabel,
	// MenuItem,
	// FormControl,
	// FormControlLabel,
	Grid,
	// Checkbox,
	Button,
	// styled,
} from "@mui/material";

import medium from "../Image/long.png";

const PartnerForm = () => {
	const head = {
		// backgroundColor: 'red',
		position: "relative",
		display: "inline",
		// marginBottom:'40px'
	};

	return (
		<div style={{ marginTop: "90px" }}>
			<Container maxWidth="lg">
				<div
					style={{
						// marginLeft:'0px',
						marginBottom: "25px",
						paddingLeft: "0px",
						position: "relative",

						fontFamily: "Lato",
					}}
					className="underlined">
					<Typography variant="h4" sx={head}>
						{" "}
						Partner With Us{" "}
						<img
							src={medium}
							alt=""
							style={{
								position: "absolute",
								// left: "-252px",
								top: "20px",
								zIndex: "-200",
								width: "245px",
							}}
							className="medium"
						/>
					</Typography>
				</div>

				<Typography variant="text" sx={{ fontSize: "15px", color: "#7d7d7d" }}>
					Fill In Your Details
				</Typography>

				<Box sx={{ marginTop: "20px" }}>
					<form>
						<Grid container spacing={2}>
							{/* first row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="org-name">Organization Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="org-names"
									name="org-name"
									placeholder="Enter Organization Name"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="contact-name">Contact Person Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="contact-name"
									name="contact-name"
									placeholder="Enter Name of Person We Can Contact"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="email">Email</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="email"
									name="email"
									placeholder="Enter Email Address of Organization"
									required
									fullWidth
									autoComplete="Email"
									// label="Name "
								/>
							</Grid>
							{/* first row end */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="Phone">Phone Number</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="phone"
									name="phone"
									type="number"
									placeholder="Enter Phone Number"
									required
									fullWidth
									autoComplete="number"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="service-name">
									Type of Service Rendered
								</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="service-name"
									name="service-name"
									placeholder="Enter Service Rendered"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="location">Location</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="location"
									name="location"
									placeholder="Enter Where Your Head Office is Located"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={12} lg={12}>
								<InputLabel id="proposal">Proposal</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="proposal"
									name="proposal"
									placeholder="Write Your Proposal Message Here"
									required
									fullWidth
									multiline
									rows={10}
									autoComplete="name"
									// label="Name "
								/>
							</Grid>
						</Grid>

						<Box
							sx={{
								marginTop: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}>
							{/* <Typography variant="text">By clicking continue, I agree to the <Link style={{textDecoration:'None', color: 'blue'}}>Terms of Use</Link> and acknowledge that i have  read the <Link style={{textDecoration:'None', color: 'blue'}}>Privacy Policy </Link> </Typography> */}

							<Button
								variant="contained"
								type="submit"
								sx={{
									mt: 3,
									width: "30%",
									mb: 2,
									backgroundColor: "blue",
									"&:hover": {
										backgroundColor: "blue",
										borderColor: "#0062cc",
										boxShadow: "none",
									},
								}}>
								Submit
							</Button>
							{/* <p style={{textAlign: 'center'}}>Already have an account? <Link style={{textDecoration:'none', color: 'blue'}}>Log In</Link> </p> */}
						</Box>
					</form>
				</Box>
			</Container>
		</div>
	);
};

export default PartnerForm;
