import React from "react";
import styled from "styled-components";

const Executives = () => {
	return (
		<ExecutivesWrapper>
			<h2 className="executives">Our Executives</h2>
			<section className="executives-holder">
				<article className="teams">
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
				</article>
				<h2>Our Team</h2>
				<article className="teams">
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p>CEO and CMO for GWC Tech School Africa</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
					<div className="profile">
						<img src="" alt="" />
						<strong>Mr. Kalu Silver</strong>
						<p className="profile-details">
							CEO and CMO for GWC Tech School Africa
						</p>
					</div>
				</article>
			</section>
		</ExecutivesWrapper>
	);
};

const ExecutivesWrapper = styled.div`
.executives{
	
	padding:20px 40px;
}

// .partners-intro{
// 	padding:20px 40px;
// }
	.executives-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify:content:space-between;
		gap:40px;
		padding:20px 40px;
	}
	

	@media screen and (min-width:40em){
.teams {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		place-items: center;
		gap: 40px;
	}
	}
	.profile {
		display: flex;
		flex-direction: column;
		align-items:center;
		justify-content:center;
        gap:30px;
		padding:20px;
		box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	}
	.profile img{
		border:1px solid red;
		border-radius:8px;
		padding:20px;
		
	}
	@media screen and (max-width:40em){
	.teams {
		display: grid;
		// grid-template-columns: repeat(4, 1fr);
		place-items: center;
		gap: 40px;
	}}
	.profile-details{
		text-align:center;
		font-size:13px;
	}`;

export default Executives;
