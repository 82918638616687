import React from "react";
import styled from "styled-components";
import cop from "./Image/Rectangle 52.png";
import opp from "./Image/Rectangle 53.png";
import { Link } from "react-router-dom";

const Opportunities = () => {
	return (
		<OpportunitiesWrapper>
			<div className="opportunity-wrapper">
				<h1
					style={{
						fontSize: "36px",
						fontWeight: "bold",
						marginBlock: "60px",
					}}>
					We Have Opportunities
				</h1>
				<section className="opportunities-section">
					<article className="opportunities">
						<img
							src={cop}
							alt=""
							data-aos="flip-right"
							data-aos-duration="3000"
						/>
						<span className="o-cta">Partner With Us</span>
						<p className="o-cta-text">
							We are committed to helping as many young Africans start a career
							in technology by providing them with access to quality technical
							education by top world experts.
						</p>
						<Link to="partner" className="ctas-btn">
							<button className="o-cta-btn"> Learn More</button>
						</Link>
					</article>
					<article className="opportunities">
						<img
							src={opp}
							alt=""
							data-aos="flip-right"
							data-aos-duration="3000"
						/>
						<span className="o-cta"> We Are Hiring</span>
						<p className="o-cta-text">
							Becoming a tutor can be a rewarding way to share your knowledge
							and help others learn. Become one of the tech expert around the
							world to train our student across Africa.
						</p>
						<a
							href="https://careers.gwctechschoolafrica.com"
							className="ctas-btn">
							<button className="o-cta-btn">Get Hired Now</button>
						</a>
					</article>
				</section>
			</div>
		</OpportunitiesWrapper>
	);
};
const OpportunitiesWrapper = styled.div`
	@media screen and (min-width: 40em) {
		.opportunities-section {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			justify-content: space-between;
			gap: 30px;
			margin-inline: 30px;
		}
		.opportunities-section img {
			width: 45vw;
			padding-block: 20px;
		}
		.opportunities {
			display: flex;
			flex-direction: column;
			align-item: center;
		}
		.cta {
			text-align: center;
			padding-block: 10px;
			font-size: 18px;
			font-weight: 550;
		}
		.cta-text {
			font-size: 14px;
			line-height: 2.3em;
			padding-block: 10px;
		}
		.ctas-btn {
			align-self: center;
			padding-block: 10px;
			cursor: pointer;
		}
		.cta-btn {
			align-self: center;
			background: #425ce8;
			color: #fff;
			// border:1px soild #425CE8;
			border: none;
			border-radius: 8px;
			padding: 15px 20px;
			cursor: pointer;
		}
		.opportunity-wrapper {
			margin-block-start: 300px;
		}
		.opportunity-wrapper h1 {
			margin-inline: 30px;
		}

		.opportunities-section img {
			width: 45vw;
			padding-block: 20px;
		}
		.opportunities {
			display: flex;
			flex-direction: column;
			align-item: center;
		}
		.o-cta {
			text-align: center;
			padding-block: 10px;
			font-size: 18px;
			font-weight: 550;
		}
		.o-cta-text {
			font-size: 14px;
			line-height: 2.3em;
			padding-block: 10px;
		}
		.ctas-btn {
			align-self: center;
			padding-block: 10px;
			cursor: pointer;
		}
		.o-cta-btn {
			align-self: center;
			background: #425ce8;
			color: #fff;
			// border:1px soild #425CE8;
			border: none;
			border-radius: 8px;
			padding: 15px 20px;
			cursor: pointer;
		}
	}
	@media screen and (max-width: 40em) {
		.opportunity-wrapper {
			margin-block-start: 40rem;
		}
		.opportunities-section {
			// margin-top:300px;
			display: grid;
			// grid-template-columns:repeat(2,1fr);
			justify-content: space-between;
			gap: 30px;
			margin-inline: 30px;
		}
		.opportunities-section img {
			// width:45vw;
			width: 100%;
			padding-block: 20px;
		}
		.opportunities {
			display: flex;
			flex-direction: column;
			// align-item:center;
		}
		.opportunity-wrapper {
			// margin-block-start:500px;
		}
		.o-cta {
			text-align: left;
			padding-block: 10px;
			font-size: 14px;
			font-weight: 550;
		}
		.opportunity-wrapper h1 {
			margin-inline: 30px;
			font-size: 23px;
		}
		.o-cta-text {
			font-size: 14px;
			line-height: 2.3em;
			padding-block: 10px;
		}
		.ctas-btn {
			align-self: center;
			padding-block: 10px;
			cursor: pointer;
		}
		.o-cta-btn {
			align-self: center;
			background: #425ce8;
			color: #fff;
			// border:1px soild #425CE8;
			border: none;
			border-radius: 8px;
			padding: 15px 20px;
			cursor: pointer;
		}
	}
`;
export default Opportunities;
