import React, { useRef, useState } from "react";
import softwarre from "../Image/sftware.png";
import datar from "../Image/Data.png";
import commune from "../Image/commune.png";
import design from "../Image/designn.png";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const url = "https://gwc-tech-school-africa.onrender.com/api/v1/faqs";
// console.log(url);
const Contact = () => {
	// const images = ["sftware.png", data, design, commune];
	// const [currentImage, setCurrentImage] = useState(software);
	const [software, setSoftware] = useState(true);
	const [data, setData] = useState(false);
	const [product, setProduct] = useState(false);
	const [communication, setCommunication] = useState(false);

	// const handleImageChange = () => {
	// 	setCurrentImage(images);
	// };
	const nameInputRef = useRef();
	const emailInputRef = useRef();
	const questionInputRef = useRef();
	const contactHandler = async (e) => {
		e?.preventDefault();
		const enteredName = nameInputRef.current.value;
		const enteredEmail = emailInputRef.current.value;
		const enteredQuestion = questionInputRef.current.value;

		let selectedCategory = "";
		if (software) {
			selectedCategory = software;
		} else if (product) {
			selectedCategory = product;
		} else if (data) {
			selectedCategory = data;
		} else if (communication) {
			selectedCategory = communication;
		}

		if (enteredEmail === "") {
			return;
		}
		try {
			const response = await fetch(
				"https://gwc-tech-school-africa.onrender.com/api/v1/faqs",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						name: enteredName,
						email: enteredEmail,
						question: enteredQuestion,
						selectedCategory: selectedCategory,
					}),
				}
			);

			const data = await response.json();
			console.log(data);
			if (response.ok) {
				console.log(data);
				//    fetchData();
				toast.success("email successfully added");
			} else {
				toast.error(`${data.error}`);
				// throw new Error("Network response was not ok");
			}
		} catch (error) {
			console.error("An error occurred:", error.message);
			// throw error;
		}
	};

	return (
		<ContactWrapper>
			<h3 className="contact-title">Contact Us</h3>
			<section className="enquiry">
				<article className="questArea">
					<p>What is your question about?</p>

					<div className="btn-section">
						<button
							value={software}
							style={{
								background: software ? "#425ce8" : "",
								color: software ? "#fff" : "",
							}}
							onClick={() => {
								setSoftware(true);
								setData(false);
								setProduct(false);
								setCommunication(false);
							}}>
							Software
						</button>
						<button
							value={product}
							style={{
								background: product ? "#425ce8" : "",
								color: product ? "#fff" : "",
							}}
							onClick={() => {
								setSoftware(false);
								setData(false);
								setProduct(true);
								setCommunication(false);
							}}>
							Product
						</button>
						<button
							value={data}
							style={{
								background: data ? "#425ce8" : "",
								color: data ? "#fff" : "",
							}}
							onClick={() => {
								setSoftware(false);
								setData(true);
								setProduct(false);
								setCommunication(false);
							}}>
							Data
						</button>
						<button
							value={communication}
							style={{
								background: communication ? "#425ce8" : "",
								color: communication ? "#fff" : "",
							}}
							onClick={() => {
								setSoftware(false);
								setData(false);
								setProduct(false);
								setCommunication(true);
							}}>
							Communication
						</button>
					</div>
					<form className="form-Area" onSubmit={contactHandler}>
						<p className="holder">
							<input
								placeholder="your name"
								className="quest-input"
								ref={nameInputRef}
							/>{" "}
							<input
								placeholder="Email"
								className="quest-input"
								ref={emailInputRef}
							/>
						</p>
						<textarea
							id="comment"
							placeholder="Enter your questions here..."
							ref={questionInputRef}
							//  name="comment" rows="4" cols="50"
						></textarea>
						{/* <input type="text" id="subject" name="subject"></input> */}
						<button
							className="contact-btn"
							// onClick={contactHandler}
							type="submit">
							Submit
						</button>
					</form>
				</article>
				<article className="imgArea">
					{software && <img src={softwarre} alt="" />}
					{product && <img src={design} alt="" />}
					{data && <img src={datar} alt="" />}
					{communication && <img src={commune} alt="" />}
				</article>
				<ToastContainer />
			</section>
		</ContactWrapper>
	);
};
const ContactWrapper = styled.div`

margin-top:80px;
	@media screen and (min-width: 40em) {
        .contact-title{
            font-size:30px;
            font-weight:bold;
            padding:40px 156px;
        }
		.enquiry {
			padding: 20px 160px;
			// adding: 20px 160px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: grid;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    grid-template-columns: repeat(2,1fr);
    -ms-flex-pack: justify;
    gap: 12vw;
        }
			.imgArea img {
				height: 36vh;
				flex: 0.4;
			}
.quest-input{
    width:16vw;
}
.contact-btn{
width:8vw;
font-size: 14px;
line-height: 25px;
background: #425ce8;
color: white;
border-radius: 10px;
padding: 10px 20px;
border: 1px solid #fff;
}
			.btn-section {
				display: flex;
				justify-content: space-between;
				gap: 10px;
			}
			.questArea {
				display: flex;
				flex: 0.6;
				flex-direction: column;
				justify-content: space-between;
			}
			.form-Area {
				display: flex;
				// flex: 0.6;
				flex-direction: column;
				// justify-content: space-between;
				gap: 40px;
			}

			.questArea input,
			textArea {
   border: none; 
  border-bottom: 1px solid black;
  outline:none;
			}

			.holder {
				display: flex;
                gap:25px;
				 justify-content: space-evenly;
			}
        }
		}
		 .btn-section button{
                   background: transparent;
			color: #425ce8;
            border-radius: 6px;
			padding:5px 10px;
			border: 1px solid #425ce8;
            cursor:pointer;
            }
input::placeholder{
//   text-align: justify;
padding-left:20px;
font-size:15px;
}
textarea {
  resize: none; /* Disable resizing */
}
 
@media screen and (max-width: 40em) {
 
.contact-btn{
width:36vw;
font-size: 14px;
line-height: 25px;
background: #425ce8;
color: white;
border-radius: 15px;
padding: 10px 20px;
border: 1px solid #fff;   
}
	.enquiry {
/* padding: 20px 160px; */
	display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
flex-direction: column-reverse;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
                align-items:center;
			}
            .imgArea{
                display:flex;
                padding-block:20px;
            }
             .imgArea img {
	 	height: 26vh;
	;
		}
        .contact-title{
            font-size:30px;
            font-weight:bold;
            text-align:center;
            // padding:40px 156px;
        }
        .btn-section {
				display: flex;
				justify-content: space-between;
				gap: 3px;
             
			}
            .btn-section button{
                   background: #fff;
			color: #425ce8;
            border-radius: 6px;
			padding:5px 10px;
			border: 1px solid #425ce8;
            cursor:pointer;
            font-size:13px;
            }
			.questArea {
				display: flex;
				// flex: 0.6;
				flex-direction: column;
				justify-content: space-between;
                gap:20px;
                align-items:center;
                padding-inline-start: 20px;
			}
			.form-Area {
				display: flex;
				// flex: 0.6;
				flex-direction: column;
				// justify-content: space-between;
				gap: 20px;
                padding-block-end:20px;
			}
			.questArea input,
			textArea {
                 outline:none;
                 border: none; 
  border-bottom: 1px solid black;
			}
			.holder {
				display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    gap: 50px;
    justify-content: space-evenly;
			}
            textArea{
                padding-inline:20px;
                width:77vw;
            }
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1024px) {

		.enquiry {
/* padding: 20px 160px; */
	display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
flex-direction: column-reverse;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
                align-items:center;
			}	
				.questArea {
				display: flex;
				// flex: 0.6;
				flex-direction: column;
				justify-content: space-between;
                gap:30px;
                align-items:center;
                padding-inline-start: 2%;
			}
			 .contact-btn {
        width: auto;
        font-size: 14px;
        line-height: 25px;
        background: #425ce8;
        color: white;
        border-radius: 10px;
        padding: 10px 20px;
        border: 1px solid #fff;
    }
	}
`;
export default Contact;
