import React from "react";
import styled from "styled-components";
import cop from "../Image/Rectangle 75 (8).png";
import opp from "../Image/Rectangle 75 (9).png";
import opps from "../Image/Rectangle 75 (10).png";
import op from "../Image/Rectangle 75 (13).png";
import copp from "../Image/Rectangle 75 (12).png";
import pp from "../Image/Rectangle 75 (11).png";
// import Programs from '../Programs'
// import sft from '../Image/sft.png'
// import data from "../Image/Group 25.png"
import prdt from "../Image/monitor.png";
// import comm from "../Image/communication.png"

const Software = () => {
  return (
    <SoftwareWrapper>
      <section className="schls-txt">
        <h1 className="sch-title_desc"> School Of Software</h1>
        <p style={{ lineHeight: "2.4em" }}>
          At the School of Software, we're passionate about empowering aspiring
          programmers with the knowledge and skills to thrive in the digital
          world. Join our community of innovators and dive into the exciting
          realm of software development.
        </p>
      </section>
      <article>
        <div className="sofware-wrapper">
          <h1 className="h1">Key Features</h1>

          <section className="sch-sctn">
            <article className="software-section">
              <article className="software">
                <img src={cop} alt="" />
                <span className="scta">Comprehensive Curriculum</span>
                <p className="scta-text">
                  We are committed to helping as many young Africans start a
                  career in technology by providing them with access to quality
                  technical education by top world experts.
                </p>
                {/* <a href='/' className="ctas-btn" ><button className='cta-btn'> Learn More</button></a> */}
              </article>
              <article className="software">
                <img src={opp} alt="" />
                <span className="scta"> Experienced Instructors</span>
                <p className="scta-text">
                  Becoming a tutor can be a rewarding way to share your
                  knowledge and help others learn. Become one of the tech expert
                  around the world to train our student across Africa.
                </p>
                {/* <a href='/' className="ctas-btn" > <button className='cta-btn'>Get Hired Now</button></a> */}
              </article>
              <article className="software">
                <img src={opps} alt="" />
                <span className="scta"> Hands-On Projects</span>
                <p className="scta-text">
                  Becoming a tutor can be a rewarding way to share your
                  knowledge and help others learn. Become one of the tech expert
                  around the world to train our student across Africa.
                </p>
                {/* <a href='/' className="ctas-btn" > <button className='cta-btn'>Get Hired Now</button></a> */}
              </article>
            </article>
            <article className="software-section">
              <article className="software">
                <img src={op} alt="" />
                <span className="scta">Collaborative Learning Environment</span>
                <p className="scta-text">
                  We are committed to helping as many young Africans start a
                  career in technology by providing them with access to quality
                  technical education by top world experts.
                </p>
                {/* <a href='/' className="ctas-btn" ><button className='cta-btn'> Learn More</button></a> */}
              </article>
              <article className="software">
                <img src={copp} alt="" />
                <span className="scta"> Career Services</span>
                <p className="scta-text">
                  Becoming a tutor can be a rewarding way to share your
                  knowledge and help others learn. Become one of the tech expert
                  around the world to train our student across Africa.
                </p>
                {/* <a href='/' className="ctas-btn" > <button className='cta-btn'>Get Hired Now</button></a> */}
              </article>
              <article className="software">
                <img src={pp} alt="" />
                <span className="scta"> Industry-Relevant Skills</span>
                <p className="scta-text">
                  Becoming a tutor can be a rewarding way to share your
                  knowledge and help others learn. Become one of the tech expert
                  around the world to train our student across Africa.
                </p>
                {/* <a href='/' className="ctas-btn" > <button className='cta-btn'>Get Hired Now</button></a> */}
              </article>
            </article>
          </section>
        </div>
      </article>
      <section>
        <section className="programs">
          <p className="p-title">Program Offerings</p>

          <main className="programs-category">
            <section className="software-desc">
              <img src={prdt} alt="" />
              <span style={{ color: "#000", fontWeight: "600" }}>
                Full-Stack Web Development
              </span>
              <span className="sft-details">
                Learn the essentials of front-end and back-end development, and
                become proficient in building dynamic and responsive web
                applications
              </span>
            </section>
            <section className="software-desc">
              <img src={prdt} alt="" />
              <span style={{ color: "#000", fontWeight: "600" }}>
                Mobile App Development
              </span>
              <span className="sft-details">
                Master the art of creating mobile applications for iOS and
                Android platforms, utilizing industry-standard tools and
                frameworks
              </span>
            </section>
            <section className="software-desc">
              <img src={prdt} alt="" />
              <span style={{ color: "#000", fontWeight: "600" }}>
                Software Engineering
              </span>
              <span className="sft-details">
                Dive deep into the fundamentals of software engineering,
                including software design, testing, and maintenance, to develop
                robust and scalable applications.
              </span>
            </section>
            <section className="software-desc">
              <img src={prdt} alt="" />
              <p style={{ color: "#000", fontWeight: "600" }}>
                Specialized Courses
              </p>
              <p className="sft-details">
                Explore specialized areas such as AI, machine learning,
                cybersecurity, and more to enhance your skill set and stay ahead
                in the evolving tech landscape.
              </p>
            </section>
          </main>
          <div className="container">
            {" "}
            <button id="btn">Enroll now</button>
          </div>
        </section>
      </section>
      {/* <Programs/> */}
    </SoftwareWrapper>
  );
};

const SoftwareWrapper = styled.div`
  .sch-title_desc {
    font-weight: bold;
    font-size: 30px;
    padding-block: 20px;
  }

  @media screen and (min-width: 40em) {
    .software-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 40px;
      margin-inline: 40px;
      padding-block-end: 40px;
    }
    .software-section img {
      /* width: 50%; */
      height: 205px;
      padding-block: 20px;
      align-self: center;
    }

    .programs-category {
      display: flex;
      justify-content: space-between;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      padding: 20px 60px;
      place-items: center;
      grid-gap: 40px;
    }
    .p-title {
      font-size: 35px;
      padding-inline: 50px;
      padding-block: 30px;
      font-weight: 500;
      line-height: 46.8px;
    }
  }

  .h1 {
    padding-inline: 30px;
  }

  .schls-txt {
    padding-inline: 30px;
    margin-top: 104px;
  }

  // .software-section {
  // 	display: grid;
  // 	grid-template-columns: repeat(3, 1fr);
  // 	justify-content: space-between;
  // 	gap: 40px;
  // 	margin-inline: 40px;
  // 	padding-block-end: 40px;
  // }

  .software {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .scta {
    text-align: center;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    // color: #425ce8;
  }
  .scta-text {
    font-size: 14px;
    text-align: center;
    line-height: 2.3em;
  }
  .ctas-btn {
    align-self: center;
    padding-block: 10px;
  }
  .cta-btn {
    align-self: center;
    background: #425ce8;
    color: #fff;
    // border:1px soild #425CE8;
    border: none;
    border-radius: 8px;
    padding: 10px;
  }
  .programs {
    margin-top: 80px;
  }
  .software-desc {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;

    // justify-content:center;
  }
  .software-desc img {
    height: 45px;
    width: 45px;
  }

  .sft-details {
    font-size: 14px;
    color: #333;
    line-height: 24px;
    /* text-align: center; */
  }
  .container {
    text-align: center;
  }
  #btn {
    font-size: 14px;
    line-height: 25px;
    background: #425ce8;
    color: white;
    border-radius: 15px;
    padding: 10px 20px;
    border: 1px solid #fff;
  }

  .container {
    padding-block: 30px;
  }

  @media screen and (max-width: 40em) {
    .software-section {
      display: grid;
      justify-content: space-between;
      gap: 40px;
      margin-inline: 40px;
      padding-block-end: 40px;
    }
    .p-title {
      font-size: 35px;
      /* padding-inline: 50px; */
      text-align: center;
      padding-block: 30px;
      font-weight: 500;
      line-height: 46.8px;
    }
    .software-section img {
      padding-block: 20px;
      align-self: center;
      width: 100%;
    }
  }
`;
export default Software;
