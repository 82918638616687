import React from "react";
import LandingPage from "./LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SharedLayout from "./Outlet/SharedLayout";
import School from "./School/School";
import Faq from "./FAQ/Faq";
// import Waiting from "./WaitingPage/Waiting";

import AboutUsPage from "./AboutUsPage";
import Software from "./School/Software";

import Anime from "./Animated/Anime";
import Contact from "./Contact/Contact";
import Executives from "./Executives/Executives";

import ScrollToTop from "./Buttton/Scroll";
import ScrollFloat from "./Buttton/Float";
import Partner from "./Partner/Partner";
import SetPasswordForm from "./screens/Authentication/setpassword";
import PartnerForm from "./screens/PartnerWithUs";
import AppForm from "./screens/Authentication/ApplicationForm";
import HireInternForm from "./screens/HireIntern";

function App() {
	return (
		<div>
			{/* <BrowserRouter> */}
			{/* <Routes> */}
			{/* <Route path='/' element={<Waiting/>}/>  */}
			{/* <Route path='faq' element={<Faq} */}
			{/* </Routes> */}

			{/* <LandingPage/> */}
			<BrowserRouter>
				<ScrollToTop />

				<ScrollFloat />
				<Routes>
					<Route path="/" element={<SharedLayout />}>
						<Route index element={<LandingPage />} />
						<Route path="school" element={<School />} />
						<Route path="faq" element={<Faq />} />
						<Route path="about" element={<AboutUsPage />} />
						<Route path="software" element={<Software />} />
						<Route path="anime" element={<Anime />} />
						<Route path="contact" element={<Contact />} />
						<Route path="executive" element={<Executives />} />
						<Route path="partner" element={<Partner />} />
						<Route path="apply" element={<AppForm />} />
						<Route path="setpassword" element={<SetPasswordForm />} />
						<Route path="partnerForm" element={<PartnerForm />} />
						<Route path="hire-intern" element={<HireInternForm />} />
					</Route>
				</Routes>
			</BrowserRouter>
			{/* </BrowserRouter> */}
		</div>
	);
}

export default App;
