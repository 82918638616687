import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Paper,
	Container,
	Typography,
	Box,
	TextField,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	Grid,
	Checkbox,
	Button,
	styled,
} from "@mui/material";

import medium from "../Image/long.png";

const HireInternForm = () => {
	const [position, setPosition] = useState("programming");

	const positionChange = (event) => {
		setPosition(event.target.value);
	};

	const head = {
		// backgroundColor: 'red',
		position: "relative",
		display: "inline",
		// marginBottom:'40px'
	};

	return (
		<div style={{ marginTop: "50px" }}>
			<Container maxWidth="lg">
				<div
					style={{
						// marginLeft:'0px',
						marginBottom: "25px",
						paddingLeft: "0px",
						position: "relative",
						fontFamily: "Lato",
					}}
					className="underlined">
					<Typography variant="h4" sx={head}>
						{" "}
						Hire An Intern{" "}
						<img
							src={medium}
							alt=""
							style={{
								position: "obsolute",
								left: "-252px",
								top: "10px",
								zIndex: "-200",
								width: "245px",
							}}
							className="medium"
						/>
					</Typography>
				</div>

				<Typography variant="text" sx={{ fontSize: "15px", color: "#7d7d7d" }}>
					Fill In Your Details
				</Typography>

				<Box sx={{ marginTop: "20px" }}>
					<form>
						<Grid container spacing={2}>
							{/* first row */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="org-name">Organization Name</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="org-names"
									name="org-name"
									placeholder="Enter Organization Name"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="email">Email</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="email"
									name="email"
									placeholder="Enter Email Address of Organization"
									required
									fullWidth
									autoComplete="Email"
									// label="Name "
								/>
							</Grid>
							{/* first row end */}

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="Phone">Phone Number</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="phone"
									name="phone"
									type="number"
									placeholder="Enter Phone Number"
									required
									fullWidth
									autoComplete="number"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="position">Positions Available</InputLabel>
								<Select
									required
									// labelId="gender-label"
									id="position"
									// value={gender}
									fullWidth
									size="small"
									// placeholder="Choose Course of Study"
									// label="Gender"
									value={position}
									onChange={positionChange}>
									{/* <MenuItem value="">
                        <em>state</em>
                        </MenuItem> */}
									<MenuItem value={"data-science"}>Data Science</MenuItem>
									<MenuItem value={"programming"}>Programming</MenuItem>
									<MenuItem value={"marketing"}>Marketing</MenuItem>
								</Select>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="location">Location</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="location"
									name="location"
									placeholder="Enter Where Your Head Office is Located"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<InputLabel id="req">Other Specific Requirements</InputLabel>
								<TextField
									sx={{ pb: 4 }}
									size="small"
									id="req"
									name="req"
									placeholder="Write Other Requirments Here"
									required
									fullWidth
									autoComplete="name"
									// label="Name "
								/>
							</Grid>
						</Grid>

						<Box
							sx={{
								marginTop: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}>
							{/* <Typography variant="text">By clicking continue, I agree to the <Link style={{textDecoration:'None', color: 'blue'}}>Terms of Use</Link> and acknowledge that i have  read the <Link style={{textDecoration:'None', color: 'blue'}}>Privacy Policy </Link> </Typography> */}

							<Button
								variant="contained"
								type="submit"
								sx={{
									mt: 3,
									width: "30%",
									mb: 2,
									backgroundColor: "blue",
									"&:hover": {
										backgroundColor: "blue",
										borderColor: "#0062cc",
										boxShadow: "none",
									},
								}}>
								Submit
							</Button>
							{/* <p style={{textAlign: 'center'}}>Already have an account? <Link style={{textDecoration:'none', color: 'blue'}}>Log In</Link> </p> */}
						</Box>
					</form>
				</Box>
			</Container>
		</div>
	);
};

export default HireInternForm;
