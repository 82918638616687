import { React, useState } from "react";
import { Link } from "react-router-dom";
import {
	// Paper,
	Container,
	Typography,
	Box,
	// TextField,
	// Select,
	InputLabel,
	// MenuItem,
	// FormControl,
	// FormControlLabel,
	Grid,
	// Checkbox,
	Button,
	// styled,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

import medium from "../../Image/long.png";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";

const SetPasswordForm = () => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const head = {
		// backgroundColor: 'red',
		position: "relative",
		display: "inline",
		// marginBottom:'40px'
	};

	return (
		<div style={{ marginTop: "50px" }}>
			<Container maxWidth="lg">
				<div
					style={{
						// marginLeft:'0px',
						marginBottom: "25px",
						paddingLeft: "0px",
						position: "relative",

						fontFamily: "Lato",
					}}
					className="underlined">
					<Typography variant="h4" sx={head}>
						{" "}
						Set Password{" "}
						<img
							src={medium}
							alt=""
							style={{
								position: "obsolute",
								left: "-220px",
								top: "10px",
								zIndex: "-200",
								width: "210px",
							}}
							className="medium"
						/>
					</Typography>
				</div>

				<Typography variant="text" sx={{ fontSize: "15px", color: "#7d7d7d" }}>
					Enter The Preferred Password You Want To Secure Your Account On The
					GWC Tech School Africa Portal
				</Typography>

				<Box sx={{ marginTop: "20px" }}>
					<form>
						<Grid container spacing={2}>
							{/* first row */}

							<Grid item xs={12} md={6} lg={6}>
								<InputLabel htmlFor="outlined-adornment-password">
									Password
								</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									type={showPassword ? "text" : "password"}
									required
									fullWidth
									size="small"
									name="password"
									autoComplete="password"
									placeholder="Enter your Password"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end">
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									// label="Password"
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={6}>
								<InputLabel htmlFor="outlined-adornment-password">
									Confirm Password
								</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									type={showPassword ? "text" : "password"}
									required
									fullWidth
									size="small"
									name="confirm-password"
									autoComplete="password"
									placeholder="Enter your Password"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end">
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									// label="Password"
								/>
							</Grid>
						</Grid>
						{/* first row end */}

						<Box
							sx={{
								marginTop: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}>
							{/* <Typography variant="text">By clicking continue, I agree to the <Link style={{textDecoration:'None', color: 'blue'}}>Terms of Use</Link> and acknowledge that i have  read the <Link style={{textDecoration:'None', color: 'blue'}}>Privacy Policy </Link> </Typography> */}

							<Button
								variant="contained"
								type="submit"
								sx={{
									mt: 3,
									width: "30%",
									mb: 2,
									backgroundColor: "blue",
									"&:hover": {
										backgroundColor: "blue",
										borderColor: "#0062cc",
										boxShadow: "none",
									},
								}}>
								Sign Up
							</Button>
							<p style={{ textAlign: "center" }}>
								Already have an account?{" "}
								<Link style={{ textDecoration: "none", color: "blue" }}>
									Log In
								</Link>{" "}
							</p>
						</Box>
					</form>
				</Box>
			</Container>
		</div>
	);
};

export default SetPasswordForm;
