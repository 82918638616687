import React, { useEffect } from "react";
import student from "./Image/student.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import Navbar from './Navbar'
// import Anime from './Animated/Anime'
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactTyped } from "react-typed";
const Header = () => {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<HeadWrap>
			<header>
				<section className="header-description">
					<article
						className="text-desc"
						data-aos="fade-right"
						data-aos-duration="3000">
						{/* <span>Launch Your Tech Career</span> */}
						<ReactTyped
							strings={["Launch Your Tech Career"]}
							typeSpeed={180}
							loop
						/>
						<p style={{ lineHeight: "2.4em" }}>
							with a degree from <b> GWC TECH SCHOOL AFRICA</b>, you'll be
							prepared for a career in the tech industry. Our programs are
							designed to give you the skills you need to succed in today's job
							market.
						</p>
						<Link to="school" className="more">
							Learn more
						</Link>
					</article>
					<article
						className="student-image"
						data-aos="fade-down"
						data-aos-easing="linear"
						data-aos-duration="1500">
						<img src={student} alt="" />
					</article>
				</section>
			</header>
		</HeadWrap>
	);
};
const HeadWrap = styled.div`
@media screen and (min-width:40em){
.header-description{
    position:relative;
    display:flex;
    background: #425CE8;
    padding:4rem 1rem;
    // height:;
    // padding-block-start:40px;
    
}
.text-desc{
    display:flex;
    padding-block:35px;
    flex-direction:column;
    gap:15px;
    margin-top:40px;
    // flex:0.7;
    // width:60%
    // justify-content:space-be;
    padding-inline:100px;
}
.text-desc span{
    color:#fff;
    font-size:48px;
    font
}
.text-desc p{
    width:41vw;
    text-align:justify;
    color:#fff;
}
.more{
    color: #425CE8;
    background:#fff;
    padding: 10px;
    text-align:center;
    width:13vw;
    border-radius:10px;
    text-decoration:none;


}

.student-image{
position: absolute;
height: 100%;
left:65% ;
top: 27%;
}
.student-image img{
    height:365px;
}


}

//tablet
@media screen and (min-width: 768px) and (max-width: 1024px){
.header-description{
    position:relative;
    display:flex;
    background: #425CE8;
    padding:4rem 1rem;
   width:100%;
    
}

.text-desc{
    margin-top:40px;
    display:flex;
    padding-block:35px;
    flex-direction:column;
    gap:2rem;
    // flex:0.7;
    // width:100%
    
    padding-inline:5px;
}
.text-desc span{
    color:#fff;
    font-size:28px;
    font
}
.text-desc p{
    width:100%;
    text-align:justify;
    color:#fff;
  
}
.student-image{
 display:none;
position: absolute;
height: 100%;
left:65% ;
top: 36%;
}

// .student-image img{
//     height:365px;
// }

}


@media screen and (max-width:40em){
header{
  display:flex;
  flex-direction:column-reverse;
  justify-content:center;
  align-items:center;
  margin-block:40px;
  // padding:20px
}
.header-description{
    // position:relative;
    // display:flex;
    background: #425CE8;
    padding:60px 20px;
    // height:380px;
    // padding-block-start:40px;
    
}
.more{
    color: #425CE8;
    background:#fff;
    padding:5px;
    text-align:center;
    // width:60%;
    border-radius:5px;
    font-size: 5vw;
    text-decoration:none;

}
.text-desc span{
    color:#fff;
    font-size:22px;
    
}
 .text-desc p{
    // width:41vw;
    font-size:12px;
    text-align:justify;
    color:#fff;
      padding-block: 20px;
} 
.student-image{
// position: absolute;
// height: 200px;
    display: none;
    height: 248px;
// left:70% ;
// top: 13%;
}

}
`;
export default Header;
