// ScrollToTopButton.js

import React, { useState, useEffect } from "react";
import { BiArrowToTop } from "react-icons/bi";
import styled from "styled-components";

const ScrollFloat = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 2980) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll); //adds button component

		return () => {
			window.removeEventListener("scroll", handleScroll); //removes the button component
			//clean up
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<Wrapper>
			{isVisible && (
				<button
					// className={`scrollToTopBtn ${isVisible ? "visible" : ""}`}
					className="scroll"
					onClick={scrollToTop}>
					{/* &#8593; */}
					<BiArrowToTop />
				</button>
			)}
		</Wrapper>
	);
};

export default ScrollFloat;

const Wrapper = styled.div`
	.scroll {
		position: fixed;
		bottom: 20%;
		right: 2%;
		font-size: 21px;
		background: #415ce8;
		font-weight: 800;
		color: white;
		border: 1px solid #f5f5f5;
		border-radius: 5px;
		padding: 16px 19px;
		border-radius: 50%;
		outline: none;
		cursor: pointer;
		-webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
		-webkit-transition: opacity 0.3s ease, transform 0.3s ease;
		transition: opacity 0.3s ease, transform 0.3s ease;
	}

	.scrollToTopBtn.visible {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}

	.scrollToTopBtn.hidden {
		opacity: 0;
		transform: translateY(100%);
	}
`;
